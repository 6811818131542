import React from 'react';

import useTopUpReminder from 'pages/dashboard/top-up/top-up-reminder/modules/service/top-up-reminder.hook';
import { DatePicker } from 'modules/components/inputs';
import { SEO } from 'modules/components/SEO';
import { Button } from 'modules/components/button';
import { TimePicker } from 'pages/dashboard/top-up/top-up-reminder/modules/components/time-picker';
import InputDate from 'pages/dashboard/modules/components/input-date';
import { Banner } from 'pages/dashboard/modules/components/banner';

import { ROUTES } from 'modules/constants/routes.const';
import {
  REMINDER_CONSTANTS,
  LINK_TO_TOP_UP,
  BUTTON_REMINDER,
  IMAGE_DATE_LOGO,
} from 'pages/dashboard/top-up/top-up-reminder/modules/constant/top-up-reminder.const';
import IconArrowCreateDepo from 'assets/images/buat-deposito-arrow.svg';
import background from 'assets/images/bg-dashboard-sm.svg';
import datelogo from 'assets/images/bx-calendar-event.svg';

const TopUpReminder = () => {
  const {
    state,
    getTomorrowDate,
    selectedDatePicker,
    handleDatePicker,
    onSubmitTopUpReminder,
    onChangeTimePicker,
    onClickDisableButtonReminder,
  } = useTopUpReminder();

  const { hourReminder, minuteReminder, isDisabledButton } = state;

  const {
    title,
    subtitle,
    description,
    instruction,
    dateFormat,
  } = REMINDER_CONSTANTS;
  return (
    <>
      <SEO title="Pengingat Transfer" />
      <Banner
        background={background}
        icon={IconArrowCreateDepo}
        link={ROUTES.topUp}
        title={title}
        id={LINK_TO_TOP_UP}
      />

      <div className="Padding PaddingBottom-2xlarge BgColor-white MinHeight Margin">
        <div className="Container">
          <h6 className="FontSize-xmedium MarginTop">
            <strong>{subtitle}</strong>
          </h6>
          <div className="PaddingTop">
            <p className="PaddingRight Typography-regular16">{description}</p>
          </div>

          <p className="Typography-regular16 MarginTop-medium MarginBottom">
            {instruction}
          </p>

          <DatePicker
            selected={selectedDatePicker()}
            onChange={handleDatePicker}
            minDate={getTomorrowDate()}
            customInput={
              <InputDate id="DtpDatePicker">
                <span>
                  <img
                    src={datelogo}
                    id={IMAGE_DATE_LOGO}
                    className="DatePicker-logo"
                    alt="calender icon"
                  />
                </span>
              </InputDate>
            }
            dateFormat={dateFormat}
            showDisabledMonthNavigation
            withPortal
            fixedHeight
          />

          <TimePicker
            hourValue={hourReminder}
            minuteValue={minuteReminder}
            onChange={onChangeTimePicker}
          />
          <div className="MarginTop-2xlarge">
            {!isDisabledButton ? (
              <Button
                className="Padding-small"
                id={BUTTON_REMINDER.id}
                onClick={onSubmitTopUpReminder}
                fullWidth>
                {BUTTON_REMINDER.title}
              </Button>
            ) : (
              <Button
                className="Button--disabled Padding-small"
                id="BtnDisabledReminder"
                onClick={onClickDisableButtonReminder}
                fullWidth>
                {BUTTON_REMINDER.title}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TopUpReminder;
