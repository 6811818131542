import React from 'react';
import { Icon } from 'modules/components/icon';
import useSenyumkuBalance from 'pages/dashboard/modules/components/senyumku-balance/modules/services/senyumku-balance.hook';
import { formatedCurrencyValue } from 'modules/utilities/add-separator-thousand.util';

const SenyumkuBalanceAmount = () => {
  const { senyumkuBalance, spinner } = useSenyumkuBalance();

  return !spinner ? (
    formatedCurrencyValue(senyumkuBalance.senyumku_balance)
  ) : (
    <Icon fa="spinner" spin theme="primary" />
  );
};

export default SenyumkuBalanceAmount;
