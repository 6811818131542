export const ROUTES = {
  home: '/',
  dashboard: '/dashboard',
  account: '/dashboard/account',
  aboutProduct: '/dashboard/account/about-product',
  profile: '/dashboard/profile',
  profilePreview: '/dashboard/profile/profile-preview',
  topUp: '/dashboard/transfer/top-up',
  topUpReminder: '/dashboard/transfer/top-up-reminder',
  topUpReminderSuccess: '/dashboard/transfer/top-up-reminder-success',
  transfer: '/dashboard/transfer',
  transferDeposit: '/dashboard/transfer/transfer-deposit',
  transferDepositSuccess: '/dashboard/transfer/transfer-deposit-success',
  transferBank: '/dashboard/transfer/instruction-top-up',
  paymentMethod: '/dashboard/transfer/method',
  ovoNotification: '/dashboard/top-up/ovo/notification',
  ovo: '/dashboard/transfer/ovo',
  portfolio: '/dashboard/portfolio',
  regis2: '/dashboard/registration',
  personalData: '/dashboard/registration/personal-data',
  employmentData: '/dashboard/registration/employment-data',
  identityData: '/dashboard/registration/identity-data',
  photoVerification: '/dashboard/registration/photo-verification',
  verification: '/dashboard/registration/verification',
  thankyou: '/registration/thank-you',
  notFound: '/not-found',
  registerVerify: '/registration/verify',
  login: '/login',
  confirmationVirtualAccount: 'dashboard/top-up/confirmation',
  redirectUrl: '/dashboard/top-up/redirect',
  faq: '/dashboard/faq',
  emailVerify: '/dashboard/registration/verification',
  dataVerify: '/dashboard/registration/email-verification',
  videoCallKyc: '/dashboard/registration/kyc',
  rejectedDataMismatch: '/dashboard/registration/rejected-data-mismatch',
  rejectednationalBlacklist: '/dashboard/rejected-national-blacklist',
  emailVerificationExpired: '/dashboard/link-expired',
  transferInstructionSenyumku:
    '/dashboard/transfer/transfer-instruction-amarbank',
};
