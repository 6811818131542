import React from 'react';

import { Input } from 'modules/components/inputs';
import { DATE_PLACEHOLDER } from 'pages/dashboard/modules/constants/dashboard.const';

const InputDate = ({ children, value, onClick, id }) => (
  <div className="DatePicker-input">
    <Input
      id={id}
      value={value}
      onClick={onClick}
      placeholder={DATE_PLACEHOLDER}
      autoComplete="off"
    />
    {children}
  </div>
);

export default InputDate;
