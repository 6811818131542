import React from 'react';
import classNames from 'classnames';
import { Icon } from 'modules/components/icon';

import './wizard.scss';

const Wizard = props => {
  // prettier-ignore
  const {
    children,
    className,
    steps,
    activeStep,
    indicator,
    ...attributes
  } = props;

  // prettier-ignore
  let classes = {
    Wizard: classNames(`Wizard`, className)
  }

  return (
    <ul {...attributes} className={classes.Wizard}>
      {steps.map((step, i) => {
        const currentStep = i + 1;
        const isDone = activeStep > currentStep;
        const isCurrent = activeStep === currentStep;

        classes = {
          WizardStep: classNames(
            `Wizard-step`,
            isCurrent ? `is-active` : null,
            isDone ? `is-done` : null,
          ),
          WizardLabel: classNames(`Wizard-label`),
          WizardIndicator: classNames(`Wizard-indicator`),
        };

        const indicatorIcon = () =>
          isDone || indicator ? <Icon fa="check" /> : currentStep;

        return (
          <li className={classes.WizardStep} key={i}>
            <div className={classes.WizardIndicator}>{indicatorIcon()}</div>
            <div className={classes.WizardLabel}>{step.name}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default Wizard;
