import { useState, useEffect, useReducer, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useApiInvoker } from '@tunaiku.npm/react-library/dist/cjs/modules/api-invoker';
import useAccount from 'pages/dashboard/account/modules/services/account.hook';

import { SnackbarContext as Snackbar } from 'modules/components/snackbar';

import { inputThousandSeparator } from 'modules/utilities/input-thousand-separator.util';
import { removeSeparator } from 'modules/utilities/string.util';
import { basicReducer } from 'modules/utilities/state.util';

import { ONLY_NUMERICAL_REGEX } from 'modules/constants/regex.const';
import {
  BANK_LIST_ENDPOINT,
  TRANSFER_OUT_ENDPOINT,
  AMAR_BANK_CODE,
} from '../constants/transfer-out.const';
import { TRANSFER_FEE } from 'modules/constants/product.const';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';

const defaultValues = {
  modalData: {
    listBank: [],
    dataConfirmationTransfer: null,
    bankName: null,
    isModalConfirmation: false,
  },
};

const useTransferOut = () => {
  const history = useHistory();
  const { openSnackbar } = useContext(Snackbar);
  const { apiInvoker } = useApiInvoker({});
  const { dataCustomer } = useAccount();
  const [state, setState] = useReducer(basicReducer, defaultValues.modalData);
  const [nominalWithSeparator, setNominalWithSeparator] = useState('');

  useEffect(() => {
    requestBankList();
  }, []);

  const requestBankList = async () => {
    const result = await apiInvoker.get(BANK_LIST_ENDPOINT);
    const listBank = arrangeBankList(result.data.Data);
    setState({ listBank });
  };

  const arrangeBankList = data => {
    const amarBankCode = AMAR_BANK_CODE;
    const bankList = [...data];
    bankList.unshift(
      bankList.splice(
        bankList.findIndex(bank => bank.bank_code === amarBankCode),
        1,
      )[0],
    );
    return bankList;
  };

  const getBankName = data => {
    const { listBank } = state;
    const bankName = listBank.find(result => result.bank_id === data.bank);
    return bankName;
  };

  const setConfirmationForm = values => {
    const bankName = getBankName(values);

    setState({
      isModalConfirmation: true,
      bankName,
      dataConfirmationTransfer: values,
    });
  };

  const setValuesForm = nominal => {
    const valueOnlyNumber = nominal.replace(ONLY_NUMERICAL_REGEX, '');

    const transformSeparatorNominal = inputThousandSeparator(
      valueOnlyNumber,
      ONLY_NUMERICAL_REGEX,
    );
    setNominalWithSeparator(transformSeparatorNominal);
  };

  const handleTransferOutSubmit = values => {
    const { bank, bankAccount, userName, valueTransfer } = values;

    const formData = {
      customer_id: dataCustomer.customer_id,
      bank_id: bank,
      account_number: bankAccount,
      recipient_name: userName,
      amount: removeSeparator(valueTransfer),
    };

    submitTransferOut(formData);
  };

  const submitTransferOut = formData => {
    apiInvoker
      .post(TRANSFER_OUT_ENDPOINT, formData)
      .then(() => history.push('/dashboard/transfer/transfer-out-success'))
      .catch(() =>
        openSnackbar({
          value: VALIDATION_MESSAGES.GLOBAL_ERROR_HANDLE,
        }),
      );
  };

  const totalCalculation =
    state.dataConfirmationTransfer &&
    removeSeparator(state.dataConfirmationTransfer.valueTransfer) -
      TRANSFER_FEE;

  return {
    requestBankList,
    dataCustomer,
    handleTransferOutSubmit,
    setConfirmationForm,
    state,
    setValuesForm,
    nominalWithSeparator,
    totalCalculation,
  };
};

export default useTransferOut;
