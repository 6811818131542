import { roundNumber } from 'modules/utilities/number.util';
import { ONLY_NUMERICAL_REGEX } from 'modules/constants/regex.const';
import { DAYS_IN_A_YEAR } from 'modules/constants/product.const';

const useResultCalculation = (state, isErrorGetPeriodList) => {
  const {
    nominalNonSeparator,
    errors,
    periodDeposit,
    interestRateDeposit,
  } = state;

  const checkNumberOnly = () =>
    nominalNonSeparator.replace(ONLY_NUMERICAL_REGEX, '');

  const isValidAmount = () =>
    checkNumberOnly() && !errors && !isErrorGetPeriodList;

  const getAmount = () => parseInt(nominalNonSeparator);

  const getPeriod = () => {
    const period = parseInt(periodDeposit);

    switch (period) {
      case 12: {
        return DAYS_IN_A_YEAR;
      }
      case 24: {
        return DAYS_IN_A_YEAR * 2;
      }
      case 36: {
        return DAYS_IN_A_YEAR * 3;
      }
      default: {
        return period * 30;
      }
    }
  };

  const getCalculationDeposit = () => {
    let resultCalculation = 0;

    if (isValidAmount()) {
      const interest = (getAmount() * interestRateDeposit) / 100;

      resultCalculation =
        (interest * getPeriod()) / DAYS_IN_A_YEAR + getAmount();
    }

    return resultCalculation;
  };

  const depositNominal = roundNumber(getCalculationDeposit());

  return {
    depositNominal,
  };
};
export default useResultCalculation;
