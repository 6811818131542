import * as Yup from 'yup';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';
import { ALPHABETIC_WITH_SPACE_REGEX } from 'modules/constants/regex.const';
import { minTransfer } from 'modules/validators/min-transfer.validator';
import { maxTransfer } from 'modules/validators/max-transfer.validator';

export const validationSchema = (validatorOptions = {}) =>
  Yup.object().shape({
    bank: Yup.string().required(VALIDATION_MESSAGES.FIELD_BANKNAME_EMPTY),
    bankAccount: Yup.string()
      .min(9, VALIDATION_MESSAGES.FIELD_BANKACCOUNT_MIN_LENGTH)
      .required(VALIDATION_MESSAGES.FIELD_NUMBER_BANKACCOUNT_EMPTY),
    userName: Yup.string()
      .matches(
        ALPHABETIC_WITH_SPACE_REGEX,
        VALIDATION_MESSAGES.FIELD_NAME_USERACCOUNT_WRONG_FORMAT,
      )
      .max(50, VALIDATION_MESSAGES.FIELD_NAME_USERACCOUNT_MAX_LENGTH)
      .max(50, VALIDATION_MESSAGES.FIELD_NAME_USERACCOUNT_MAX_LENGTH)
      .required(VALIDATION_MESSAGES.FIELD_NAME_USERACCOUNT_EMPTY),
    valueTransfer: Yup.string()
      .required(VALIDATION_MESSAGES.FIELD_NOMINAL_TRANSFER_OUT_EMPTY)
      .test(
        'valueTransfer',
        VALIDATION_MESSAGES.FIELD_NOMINAL_TRANSFER_OUT,
        value => minTransfer(value, validatorOptions.minTransfer),
      )
      .test('valueTransfer', VALIDATION_MESSAGES.FIELD_TRANSFER_MAX, value =>
        maxTransfer(value, validatorOptions.senyumku),
      )
      .test(
        'valueTransfer',
        VALIDATION_MESSAGES.FIELD_MAXIMUM_NOMINAL_TRANSFER_OUT,
        value => maxTransfer(value, validatorOptions.maxTransfer),
      ),
  });
