import React from 'react';

import { SEO } from 'modules/components/SEO';
import { Calculation } from 'pages/modules/components/calculation';
import { Banner } from 'pages/dashboard/modules/components/banner';
import { DEFAULT_VALUE } from 'modules/constants/product.const';
import { ROUTES } from 'modules/constants/routes.const';

import background from 'assets/images/bg-dashboard-sm.svg';
import IconArrowTransfer from 'assets/images/arrow-transfer.svg';

const ProductSimulation = () => {
  const dataCalculation = {
    nominal: DEFAULT_VALUE.amount,
    period: DEFAULT_VALUE.period,
    url: '/dashboard/transfer/method',
    theme: 'primary',
    idAction: 'BtnToTransferMethod',
    content: {
      button: 'Pilih Metode Transfer Deposito',
      product: 'Produk Investasi',
      nominal: 'Nominal Deposito',
    },
  };

  return (
    <div className="ProductSimulation">
      <SEO title="Buat Deposito" />
      <Banner
        background={background}
        icon={IconArrowTransfer}
        link={ROUTES.transfer}
        id="LinkBackTransfer"
        title="Buat Deposito"
      />
      {dataCalculation.nominal && (
        <div className="Padding">
          <Calculation {...dataCalculation} />
        </div>
      )}
    </div>
  );
};

export default ProductSimulation;
