import React from 'react';
import { InputNominal } from 'modules/components/inputs';
import { separatorThousand } from 'modules/utilities/add-separator-thousand.util';
import { MIN_SAVING } from 'modules/constants/product.const';
import './nominal-calculation.scss';

const NominalCalculation = props => {
  const { nominal, nominalAttributes, errorMessage } = props;

  return (
    <div className="NominalCalculation">
      <label
        htmlFor="TxtAmountDeposit"
        className="MarginTop-large Typography-regular16">
        {nominal}
      </label>
      <InputNominal {...nominalAttributes} />
      <div className="FontSize-10 Color-darkGrey MarginBottom">
        Minimal deposito Rp{separatorThousand(MIN_SAVING)}
      </div>
      {errorMessage && (
        <div className="NominalCalculation-messageError">{errorMessage}</div>
      )}
    </div>
  );
};

export default NominalCalculation;
