import React from 'react';
import classNames from 'classnames';
import ReactInputMask from 'react-input-mask';

const defaultProps = {
  maskingCharacter: null,
};

const InputMask = props => {
  /* prettier-ignore */
  const {
    mask,
    maskingCharacter,
    className,
    children,
    isFieldError,
    ...attributes
  } = props;

  const cssClasses = classNames(`Input`, {
    [`Input--danger`]: !!isFieldError,
    className,
  });

  return (
    <ReactInputMask
      mask={mask}
      maskChar={maskingCharacter}
      className={cssClasses}
      {...attributes}
    />
  );
};

InputMask.defaultProps = defaultProps;

export default InputMask;
