import React from 'react';
import classNames from 'classnames';

const defaultProps = {
  tag: 'div',
};

/**
 * <FormField render={() => (
 * 	<InputSetExtra></InputSetExtra>
 * 	<Input />
 * 	<InputSetExtra></InputSetExtra>
 * )}>
 *
 * </FormField>
 */

const InputSet = props => {
  // prettier-ignore
  const {
    tag: Tag,
    className,
    children,
    ...attributes
  } = props;

  const classes = classNames(`Input-set`, className);

  return (
    <Tag {...attributes} className={classes}>
      {children}
    </Tag>
  );
};

InputSet.defaultProps = defaultProps;

export default InputSet;
