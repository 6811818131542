import React from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { Header } from 'modules/components/header';
import { Icon } from 'modules/components/icon';
import { ROUTES } from 'modules/constants/routes.const';
import { Form, FormField } from 'modules/components/form';
import {
  Select,
  DatePicker,
  Autocomplete,
  Textarea,
} from 'modules/components/inputs';
import { Button } from 'modules/components/button';
import { SwitchToggle } from 'modules/components/switch-toggle';
import { Wizard } from 'modules/components/wizard';
import { Separator } from 'modules/components/separator';
import SecurityInfoCard from 'pages/modules/components/security-info-card';
import InputDate from 'pages/dashboard/modules/components/input-date';

import { validationSchema } from 'pages/dashboard/registration/identity-data/modules/services/identity-data.validation';
import useIdentityData from './modules/services/identity-data.hook';

import { REGISTRATION_STEPS } from '../modules/constants/registration-steps.const';
import {
  BLOOD_TYPE_FIELDS,
  NATIONALITY_FIELDS,
  DATE_FORMAT,
  GENDER_FIELDS,
  MARITAL_STATUS_FIELDS,
  NIK_LENGTH,
  RELIGION_FIELDS,
  MAX_HAMLET_NUMBER_LENGTH,
  MAX_NEIGHBOURHOOD_NUMBER_LENGTH,
} from './modules/constants/identity-data.const';

import './identity-data.scss';

const IdentityData = () => {
  const history = useHistory();
  const {
    initialValues,
    handleIdentityDataSubmit,
    onChangeNIKField,
    urbanVillage,
    onClickUrbanVillage,
    onChangeUrbanVillage,
    onChangeSubDistrict,
  } = useIdentityData();

  const {
    isRequestUrbanVillage,
    urbanVillageResults,
    errorMessage,
  } = urbanVillage;

  const renderOptionField = optionField =>
    optionField.map(field => (
      <option key={field} value={field}>
        {field}
      </option>
    ));

  return (
    <>
      <Header isDisableRedirect />

      <SecurityInfoCard>
        Data ini diperlukan sebagai syarat dari OJK (Otoritas Jasa Keuangan).
        Kerahasiaan informasi kamu akan kami jaga
      </SecurityInfoCard>

      <div className="IdentityData Container Flex-column FlexGrow-1  PaddingTop PaddingBottom">
        <div className="PaddingBottom PaddingTop">
          <Wizard activeStep={3} steps={REGISTRATION_STEPS} />
          <Separator className="MarginBottom-0" />
        </div>

        <div className="PaddingBottom-medium">
          <Button
            size="small"
            id="LinkBackToEmploymentData"
            className="Padding-0 MarginBottom"
            onClick={() => history.push(ROUTES.employmentData)}
            isText>
            <div className="Flex FontSize-xsmall">
              <Icon fa="chevron-left" className="MarginRight-small" />
              Kembali ke Pekerjaan
            </div>
          </Button>
          <h6 className="Typography-bold16">Data KTP</h6>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={handleIdentityDataSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
          render={formik => {
            return (
              <Form className="Flex-column FlexGrow-1">
                <div className="Flex-column FlexGrow-1">
                  <FormField
                    label="Nomor KTP"
                    id="TxtIDNumber"
                    name="nik"
                    type="tel"
                    required
                    onChange={onChangeNIKField(formik)}
                    maxLength={NIK_LENGTH}
                    fsExclude
                  />
                  <FormField
                    id="TxtIDCard"
                    label="Nama sesuai KTP"
                    name="name"
                    required
                    fsExclude
                  />
                  <FormField
                    id="TxtPlaceOfBirth"
                    label="Tempat lahir"
                    name="place_of_birth"
                    required
                  />
                  <FormField
                    id="DatepickerBirthDate"
                    label="Tanggal lahir"
                    name="birth_date"
                    inputTag={DatePicker}
                    customInput={<InputDate />}
                    required
                    selectedDate={formik.values.birth_date}
                    formControlProps={{ style: { display: 'block' } }}
                    onChange={date => {
                      formik.setFieldValue('birth_date', date);
                    }}
                    dateBlur={formik.handleBlur}
                    dateFormat={DATE_FORMAT}
                  />
                  <FormField
                    id="DrpGender"
                    inputTag={Select}
                    label="Jenis kelamin"
                    name="gender"
                    required>
                    <option value=""></option>
                    {renderOptionField(GENDER_FIELDS)}
                  </FormField>
                  <FormField
                    id="DrpBloodType"
                    inputTag={Select}
                    label="Golongan darah"
                    name="blood_type"
                    required>
                    <option value="-">-</option>
                    {renderOptionField(BLOOD_TYPE_FIELDS)}
                  </FormField>
                  <Separator />
                  <FormField
                    id="TxtHomeAddress"
                    label="Alamat sesuai KTP"
                    name="home_address"
                    required
                    inputTag={Textarea}
                    rows={3}
                  />
                  <div className="Grids">
                    <FormField
                      id="TxtHamletNumber"
                      label="RT"
                      name="hamlet_number"
                      required
                      className="Grid"
                      maxLength={MAX_HAMLET_NUMBER_LENGTH}
                    />
                    <FormField
                      id="TxtNeighbourhoodNumber"
                      label="RW"
                      name="neighbourhood_number"
                      required
                      className="Grid"
                      maxLength={MAX_NEIGHBOURHOOD_NUMBER_LENGTH}
                    />
                  </div>
                  <FormField
                    id="TxtUrbanVillage"
                    label="Kelurahan/Desa"
                    name="urban_village"
                    required
                    inputTag={Autocomplete}
                    onChange={onChangeUrbanVillage(formik)}
                    isLoading={isRequestUrbanVillage}
                    autoComplete="off">
                    {urbanVillageResults.length > 0 && (
                      <div className="UrbanVillage MarginBottom">
                        <div className="ListGroup">
                          {urbanVillageResults.map(urbanVillageResult => (
                            <div
                              className="ListGroup-item pd-xsmall"
                              value={urbanVillageResult.ID}
                              onClick={() =>
                                onClickUrbanVillage(formik, urbanVillageResult)
                              }
                              key={urbanVillageResult.ID}>
                              {`${urbanVillageResult.VillageName}, ${urbanVillageResult.DistrictName}`}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </FormField>

                  {errorMessage && (
                    <div className="Color-danger FontSize-small MarginBottom">
                      {errorMessage}
                    </div>
                  )}

                  <FormField
                    id="TxtSubDistrict"
                    label="Kecamatan"
                    name="sub_district"
                    onChange={onChangeSubDistrict(formik)}
                    required
                    autoComplete="off"
                  />

                  <Separator />
                  <FormField
                    id="DrpReligion"
                    inputTag={Select}
                    label="Agama"
                    name="religion"
                    required>
                    <option value=""></option>
                    {renderOptionField(RELIGION_FIELDS)}
                  </FormField>
                  <FormField
                    id="DrpMaritalStatus"
                    inputTag={Select}
                    label="Status perkawinan"
                    name="marital_status"
                    required>
                    <option value=""></option>
                    {renderOptionField(MARITAL_STATUS_FIELDS)}
                  </FormField>
                  <FormField
                    id="DrpCitizenship"
                    inputTag={Select}
                    label="Kewarganegaraan"
                    name="nationality"
                    required>
                    {renderOptionField(NATIONALITY_FIELDS)}
                  </FormField>
                  <FormField
                    id="ToggleIsLifetimeValidity"
                    name="is_card_valid_for_life"
                    type="checkbox"
                    inputTag={SwitchToggle}
                    checked={formik.values.is_card_valid_for_life}
                    checkedLabel="KTP berlaku seumur hidup"
                    uncheckedLabel="KTP berlaku hinggal tanggal :"
                    onChange={evt => {
                      formik.setFieldValue(
                        'is_card_valid_for_life',
                        evt.target.checked,
                      );
                    }}
                    className="MarginBottom-0"
                  />
                  {!formik.values.is_card_valid_for_life && (
                    <FormField
                      id="DatepickerValidityDate"
                      name="card_date_expired"
                      inputTag={DatePicker}
                      customInput={<InputDate />}
                      selectedDate={formik.values.card_date_expired}
                      formControlProps={{ style: { display: 'block' } }}
                      onChange={date => {
                        formik.setFieldValue('card_date_expired', date);
                      }}
                      dateBlur={formik.handleBlur}
                      dateFormat={DATE_FORMAT}
                    />
                  )}
                </div>
                <div className="PaddingTop-large">
                  <p>
                    Pastikan semua data benar sebelum kamu lanjut ke langkah
                    berikutnya.
                  </p>
                  <Button
                    id="BtnIdentityDataSave"
                    type="submit"
                    theme="secondary"
                    fullWidth
                    className="MarginTop">
                    Simpan Data KTP
                  </Button>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </>
  );
};

export default IdentityData;
