import { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useApiInvoker } from '@tunaiku.npm/react-library/dist/cjs/modules/api-invoker';

import { SnackbarContext } from 'modules/components/snackbar';
import useRegistration from 'pages/dashboard/registration/modules/services/registration.hook';

import { ROUTES } from 'modules/constants/routes.const';
import {
  ENDPOINT_VERIFICATION,
  ENDPOINT_KYC,
} from 'pages/dashboard/registration/photo-verification/photo-verification-state/modules/constants/photo-verification-state.const';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';
import { PhotoVerificationStatusContext } from 'pages/dashboard/registration/photo-verification/modules/services/photo-verification.provider';

const usePhotoVerificationState = () => {
  const { apiInvoker } = useApiInvoker({});
  const { getRegisId } = useRegistration();
  const { openSnackbar } = useContext(SnackbarContext);
  const [checkbox, setCheckbox] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    isKtpPhotoUploaded,
    isSelfiePhotoUploaded,
    isLeftSelfiePhotoUploaded,
    isRightSelfiePhotoUploaded,
  } = useContext(PhotoVerificationStatusContext);
  const history = useHistory();

  useEffect(() => {
    handleDisabledButton();
    handleDisableBackBrowser();
  }, [
    isKtpPhotoUploaded,
    isSelfiePhotoUploaded,
    isLeftSelfiePhotoUploaded,
    isRightSelfiePhotoUploaded,
    checkbox,
  ]);

  const onSubmitKycData = () => {
    setIsSubmitting(true);
    const reqBody = {
      regs_id: getRegisId(),
    };
    apiInvoker
      .post(ENDPOINT_KYC, reqBody)
      .then(handleKycSuccess)
      .catch(handleKycFailed);
  };

  const handleDisabledButton = () => {
    if (
      isKtpPhotoUploaded &&
      isSelfiePhotoUploaded &&
      isLeftSelfiePhotoUploaded &&
      isRightSelfiePhotoUploaded &&
      checkbox
    ) {
      return setIsDisabled(false);
    }
    return setIsDisabled(true);
  };

  const handleKycSuccess = () => {
    setIsSubmitting(false);
    onSubmitEmailVerification()
  };

  const handleKycFailed = () => {
    setIsSubmitting(false);

    openSnackbar({
      value: VALIDATION_MESSAGES.GLOBAL_ERROR,
    });
  }

  const onSubmitEmailVerification = () => {
    const reqBody = {
      regs_id: getRegisId(),
    };
    apiInvoker
      .post(ENDPOINT_VERIFICATION, reqBody)
      .then(() => history.push(ROUTES.verification))
      .catch(handleEmailVerificationError);
  };

  const handleEmailVerificationError = err => {
    const { Data: data } = err.response.data;
    const { Code: code } = data;

    if (code) {
      return history.push(ROUTES.verification);
    }
    return openSnackbar({
      value: VALIDATION_MESSAGES.GLOBAL_ERROR,
    });
  };

  const handleDisableBackBrowser = () => {
    if (history.location.pathname === ROUTES.photoVerification) {
      window.onpopstate = () => history.push(ROUTES.photoVerification);
    }
  };

  return {
    onSubmitEmailVerification,
    onSubmitKycData,
    isDisabled,
    checkbox,
    setCheckbox,
    isSubmitting,
  };
};

export default usePhotoVerificationState;
