import React from 'react';
import classNames from 'classnames';
import { InputSet, Input } from 'modules/components/inputs';

import './input-nominal.scss';

const InputNominal = props => {
  const { className, ...attributes } = props;

  const cssClasses = {
    InputNominal: classNames(`InputNominal`),
    InputNominalAddonBefore: classNames(`InputNominal-addonBefore`),
    InputNominalAmount: classNames(`InputNominal-amount`, className),
  };

  return (
    <InputSet className={cssClasses.InputNominal}>
      <div className={cssClasses.InputNominalAddonBefore}>Rp</div>
      <Input className={cssClasses.InputNominalAmount} {...attributes} />
    </InputSet>
  );
};

export default InputNominal;
