import React from 'react';
import classNames from 'classnames';

import './button.scss';

const defaultProps = {
  tag: 'button',
  theme: 'primary',
  disabled: false,
  isText: false,
};

const Button = props => {
  const {
    tag: Tag,
    className,
    children,
    theme,
    size,
    outlined,
    elevated,
    isText,
    rounded,
    fullWidth,
    disabled,
    ...attributes
  } = props;

  const getDisabledAttribute = () => {
    if (disabled && Tag === 'button') {
      return true;
    }

    return false;
  };

  const cssClasses = classNames(
    `Button`,
    theme ? `Button--${theme}` : null,
    size ? `Button--${size}` : null,
    outlined ? `Button--outlined` : null,
    fullWidth ? `Button--fullWidth` : null,
    elevated ? `Button--elevated` : null,
    isText ? `Button--text` : null,
    disabled ? `is-disabled` : null,
    rounded ? `Button--rounded` : null,
    className,
  );

  return (
    <Tag
      {...attributes}
      disabled={getDisabledAttribute()}
      className={cssClasses}>
      {children}
    </Tag>
  );
};

Button.defaultProps = defaultProps;

export default Button;
