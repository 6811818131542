import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import { Header } from 'modules/components/header';
import { Icon } from 'modules/components/icon';
import { Wizard } from 'modules/components/wizard';
import { Button } from 'modules/components/button';
import { Form, FormField } from 'modules/components/form';
import { Select } from 'modules/components/inputs';
import { Separator } from 'modules/components/separator';
import SecurityInfoCard from 'pages/modules/components/security-info-card';
import useEmploymentData from 'pages/dashboard/registration/employment-data/modules/services/employment-data.hook';

import { REGISTRATION_STEPS } from '../modules/constants/registration-steps.const';
import { ROUTES } from 'modules/constants/routes.const';
import {
  SOURCE_INCOME_FIELD,
  MONTHLY_INCOME_FIELD,
  WORK_TYPE_FIELD,
  POSITION_FIELD,
  INDUSTRIAL_COMPANY_FIELD,
} from './modules/constants/employment-data.const';
import { validationSchema } from 'pages/dashboard/registration/employment-data/modules/services/employment-data.validation';

const EmploymentData = () => {
  const history = useHistory();
  const {
    initialValues,
    handleEmploymentRegistrationSubmit,
  } = useEmploymentData();

  const renderOptionField = optionField =>
    optionField.map((field, index) => (
      <option value={field} key={index}>
        {field}
      </option>
    ));

  return (
    <>
      <Header isDisableRedirect />

      <SecurityInfoCard>
        Data ini diperlukan sebagai syarat dari OJK (Otoritas Jasa Keuangan).
        Kerahasiaan informasi kamu akan kami jaga
      </SecurityInfoCard>

      <div className="Container Flex-column FlexGrow-1 PaddingTop PaddingBottom">
        <div className="PaddingBottom PaddingTop">
          <Wizard activeStep={2} steps={REGISTRATION_STEPS} />
          <Separator className="MarginBottom-0" />
        </div>

        <div className="PaddingBottom-medium">
          <Button
            size="small"
            id="LinkBackToPersonalData"
            className="Padding-0 MarginBottom"
            onClick={() => history.push(ROUTES.personalData)}
            isText>
            <div className="Flex FontSize-xsmall">
              <Icon fa="chevron-left" className="MarginRight-small" />
              Kembali ke Data Diri
            </div>
          </Button>
          <h6 className="Typography-bold16">Data Pekerjaan</h6>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleEmploymentRegistrationSubmit}
          enableReinitialize={true}
          render={({ values }) => {
            return (
              <Form className="Flex-column FlexGrow-1">
                <FormField
                  id="DrpSourceIncome"
                  inputTag={Select}
                  label="Sumber Pendapatan"
                  name="source_income"
                  required>
                  <option value=""></option>
                  {renderOptionField(SOURCE_INCOME_FIELD)}
                </FormField>
                <FormField
                  id="DrpMonthlyIncome"
                  inputTag={Select}
                  label="Pendapatan Bulanan (dalam Rp)"
                  name="monthly_income"
                  required>
                  <option value=""></option>
                  {renderOptionField(MONTHLY_INCOME_FIELD)}
                </FormField>
                <FormField
                  id="DrpWorkType"
                  inputTag={Select}
                  label="Jenis Pekerjaan"
                  name="work_type"
                  required>
                  <option value=""></option>
                  {renderOptionField(WORK_TYPE_FIELD)}
                </FormField>
                <ExtraFields type={values.work_type} />
                <div className="PaddingTop-large">
                  <p>
                    Pastikan semua data benar sebelum kamu lanjut ke langkah
                    berikutnya.
                  </p>
                  <Button
                    id="BtnEmploymentDataNext"
                    theme="secondary"
                    type="submit"
                    fullWidth
                    className="MarginTop">
                    Simpan Data Pekerjaan
                  </Button>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </>
  );
};

const ExtraFields = ({ type }) => {
  switch (type) {
    case '':
    case 'Militer / Polisi':
    case 'Tidak Bekerja':
    case 'Ibu Rumah Tangga':
    case 'Pelajar / Mahasiswa':
    case 'Pensiunan': {
      return null;
    }
    default: {
      return (
        <>
          <FormField
            inputTag={Select}
            name="position"
            id="DrpPosition"
            label="Jabatan"
            required>
            <option value=""></option>
            {POSITION_FIELD.map((field, index) => (
              <option value={field} key={index}>
                {field}
              </option>
            ))}
          </FormField>
          <FormField
            id="DrpIndustrialCompany"
            inputTag={Select}
            name="industrial_company"
            label="Industri Perusahaan"
            required>
            <option value=""></option>
            {INDUSTRIAL_COMPANY_FIELD.map((field, _i) => (
              <option key={_i} value={field}>
                {field}
              </option>
            ))}
          </FormField>
          <FormField
            id="TxtCompanyName"
            name="company_name"
            label="Nama Perusahaan"
            helper={() => {
              return (
                <div className="FontSize-small Color-grey">
                  Isi "Tidak ada" jika Anda bekerja sendiri
                </div>
              );
            }}
            required
          />
        </>
      );
    }
  }
};

export default EmploymentData;
