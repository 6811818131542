import { useEffect, useReducer, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import format from 'date-fns/format';
import { useApiInvoker } from '@tunaiku.npm/react-library/dist/cjs/modules/api-invoker';

import { SnackbarContext } from 'modules/components/snackbar';
import useStorageManager from 'modules/services/storage-manager.hook';
import { basicReducer } from 'modules/utilities/state.util';

import { ROUTES } from 'modules/constants/routes.const';
import { LOCAL_STORAGE } from 'modules/constants/local-storage.const';
import {
  REMINDER_CONSTANTS,
  TOP_UP_REMINDER_ENDPOINT,
} from '../constant/top-up-reminder.const';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';

const defaultValues = {
  hourReminder: '13',
  minuteReminder: '00',
  startDate: null,
  nominal: '',
  periodDeposit: '',
  product: '',
  resultCalculation: '',
  isDisabledButton: false,
};

const useTopUpReminder = () => {
  const history = useHistory();
  const { apiInvoker } = useApiInvoker({});
  const { openSnackbar } = useContext(SnackbarContext);
  const { getPlainDataFromLocalStorage } = useStorageManager();
  const [state, setState] = useReducer(basicReducer, defaultValues);

  const {
    hourReminder,
    minuteReminder,
    startDate,
    nominal,
    periodDeposit,
    product,
    resultCalculation,
  } = state;

  useEffect(() => {
    setDataCalculation();
  }, [periodDeposit, nominal]);

  useEffect(() => {
    handleDisabledButton();
  }, [hourReminder, minuteReminder]);

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    return tomorrow.setDate(tomorrow.getDate() + 1);
  };

  const handleDatePicker = date => {
    setState({
      startDate: date,
    });
  };

  const onChangeTimePicker = (hour, minute) => {
    setState({
      hourReminder: hour,
      minuteReminder: minute,
    });
  };

  const selectedDatePicker = () => {
    if (startDate === null) {
      setState({
        startDate: getTomorrowDate(),
      });
    } else {
      return startDate;
    }
  };

  const setDataCalculation = () => {
    const {
      nominal: nominalDeposit,
      product: productDeposit,
      period,
      result,
    } = getPlainDataFromLocalStorage(LOCAL_STORAGE.resultCalculation);

    setState({
      nominal: nominalDeposit,
      product: productDeposit,
      periodDeposit: period,
      resultCalculation: result,
    });
  };

  const formatReminderDateTime = () => {
    const formatDate = format(
      new Date(startDate),
      REMINDER_CONSTANTS.dateOnSubmitFormat,
    );

    const resultDate = formatDate;
    const resultTime = `${hourReminder}:${minuteReminder}`;
    const resultDateTime = `${resultDate} ${resultTime}`;

    return resultDateTime;
  };

  const handleDisabledButton = () => {
    setState({
      isDisabledButton: hourReminder.length <= 1 || minuteReminder.length <= 1,
    });
  };

  const onClickDisableButtonReminder = () =>
    openSnackbar({
      value: REMINDER_CONSTANTS.disableReminder,
    });

  const onSubmitTopUpReminder = () => {
    const formData = {
      calculation_result: resultCalculation,
      product_id: product,
      reminder_date_time: formatReminderDateTime(),
      amount: nominal,
      period_in_month: parseInt(periodDeposit),
    };

    fetchTopUpReminder(formData);
  };

  const fetchTopUpReminder = formData => {
    apiInvoker
      .post(TOP_UP_REMINDER_ENDPOINT, formData)
      .then(handleTopUpReminderSuccess)
      .catch(handleTopUpReminderFailed);
  };

  const handleTopUpReminderSuccess = () =>
    history.push(ROUTES.topUpReminderSuccess);

  const handleTopUpReminderFailed = () =>
    openSnackbar({
      value: VALIDATION_MESSAGES.DATA_FETCHING_ERROR,
    });

  return {
    state,
    setState,
    getTomorrowDate,
    selectedDatePicker,
    handleDatePicker,
    onSubmitTopUpReminder,
    onChangeTimePicker,
    onClickDisableButtonReminder,
  };
};
export default useTopUpReminder;
