import * as Yup from 'yup';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';

const isUnemployed = type => {
  switch (type) {
    case '':
    case 'Tidak Bekerja':
    case 'Militer / Polisi':
    case 'Ibu Rumah Tangga':
    case 'Pelajar / Mahasiswa':
    case 'Pensiunan':
      return false;
    default:
      return true;
  }
};

export const validationSchema = Yup.object().shape({
  source_income: Yup.string().required(
    VALIDATION_MESSAGES.FIELD_INCOME_SOURCE_NOT_SELECTED,
  ),
  monthly_income: Yup.string().required(
    VALIDATION_MESSAGES.FIELD_MONTHLY_INCOME_NOT_SELECTED,
  ),
  work_type: Yup.string().required(
    VALIDATION_MESSAGES.FIELD_WORK_TYPE_NOT_SELECTED,
  ),
  position: Yup.string().when('work_type', {
    is: val => isUnemployed(val),
    then: Yup.string().required(
      VALIDATION_MESSAGES.FIELD_POSITION_NOT_SELECTED,
    ),
  }),
  industrial_company: Yup.string().when('work_type', {
    is: val => isUnemployed(val),
    then: Yup.string().required(
      VALIDATION_MESSAGES.FIELD_INDUSTRIAL_COMPANY_NOT_SELECTED,
    ),
  }),
  company_name: Yup.string().when('work_type', {
    is: val => isUnemployed(val),
    then: Yup.string()
      .required(VALIDATION_MESSAGES.FIELD_COMPANY_NAME_EMPTY)
      .max(50, VALIDATION_MESSAGES.FIELD_COMPANY_NAME_MAX_LENGTH),
  }),
});
