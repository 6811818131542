import React from 'react';
import { ReactComponent as IconInfo } from 'assets/images/ic-info.svg';

const SecurityInfoCard = ({ children }) => {
  return (
    <div className="Card Card--information BgColor-white PaddingLeft-xsmall">
      <div className="Flex PaddingLeft-2xsmall">
        <IconInfo className="Color-secondary Margin-auto" width="50" />
        <div className="FontWeight-semibold MarginLeft-small Padding-0 PaddingLeft-2xsmall">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SecurityInfoCard;
