import { useContext } from 'react';
import { RegistrationContext } from './registration.provider';

const useRegistration = () => {
  const { getRegisId } = useContext(RegistrationContext);

  return {
    getRegisId,
  };
};

export default useRegistration;
