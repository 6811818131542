import React from 'react';
import ButtonCalculation from '../button-calculation';
import { ReactComponent as InterestIcon } from 'assets/images/interest-icon.svg';

const PeriodInterestCalculation = props => {
  const {
    periodInterestList,
    activeButton,
    handleSelectedPeriod,
    isNominalAboveOneBillion,
  } = props;

  return (
    <div className="PeriodInterestCalculation">
      <div className="PaddingBottom">
        {isNominalAboveOneBillion
          ? 'Pilih Periode'
          : 'Pilih Periode dan Bunga per tahun'}
      </div>

      {isNominalAboveOneBillion && (
        <div id="VerifyTextBungaPerTahun" className="Flex MarginBottom">
          <InterestIcon className="MarginRight-2xsmall" />
          <div className="FontSize-xsmall FontWeight-bold Color-green80">
            Bunga 3.5% per tahun
          </div>
        </div>
      )}

      {periodInterestList.length > 0 &&
        periodInterestList.map((periodList, index) => {
          const {
            period,
            interestRate,
            interestRateAfterValidation,
          } = periodList;

          const selectedInterest = isNominalAboveOneBillion
            ? interestRateAfterValidation
            : interestRate;

          return (
            <ButtonCalculation
              id={`BtnPeriod${period}Calculation`}
              key={index}
              period={period}
              interestRate={selectedInterest}
              className={activeButton === period && 'is-active'}
              handleCalculation={() =>
                handleSelectedPeriod(period, selectedInterest)
              }
              isNominalAboveOneBillion={isNominalAboveOneBillion}
            />
          );
        })}
    </div>
  );
};

export default PeriodInterestCalculation;
