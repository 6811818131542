export const REMINDER_CONSTANTS = {
  title: 'Pengingat Transfer',
  subtitle: 'Pilih waktu pengingat Transfer',
  description:
    'Kami akan mengingatkan kamu untuk Transfer via email sesuai dengan jadwal yang kamu tentukan',
  instruction: 'Ingatkan saya untuk Transfer pada :',
  separator: ':',
  timezone: 'WIB',
  dateFormat: 'ccc, d MMMM yyyy',
  dateOnSubmitFormat: 'dd/MM/yyyy',
  timeDefault: '00',
  hourMax: '23',
  minuteMax: '59',
  disableReminder:
    'Silahkan pilih tanggal dan waktu pengingat deposito terlebih dahulu',
};

export const LINK_TO_TOP_UP = 'LinkToTopUp';
export const INPUT_HOUR = 'TxtHourTopUpReminder';
export const INPUT_MINUTE = 'TxtMinuteTopUpReminder';
export const IMAGE_DATE_LOGO = 'ImgDateLogo';
export const IMAGE_TIME_LOGO = 'ImgTimeLogo';

export const BUTTON_REMINDER = {
  id: 'BtnSubmitTopUpReminder',
  title: 'Simpan Pengingat',
};

export const TOP_UP_REMINDER_ENDPOINT = '/topup/reminder';
