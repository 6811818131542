import React from 'react';
import classNames from 'classnames';
import './checkbox.scss';

const defaultProps = {
  tag: 'div',
  inputTag: 'input',
  type: 'checkbox',
};

const Checkbox = props => {
  /* prettier-ignore */
  const {
    tag: Tag,
    inputTag: InputTag,
    className,
    children,
    ...attributes
  } = props;

  const cssClasses = {
    Checkbox: classNames('Checkbox', className),
    CheckboxControl: classNames('Checkbox-control'),
  };

  // Assigns `checked` attribute according the existence of `value`.
  attributes.checked = props.value && true;

  return (
    <Tag className={cssClasses['Checkbox']}>
      <InputTag
        className={cssClasses['CheckboxControl']}
        id={attributes.name}
        {...attributes}
      />
      {attributes.label ? (
        <label
          htmlFor={attributes.name}
          dangerouslySetInnerHTML={{ __html: attributes.label }}
        />
      ) : null}
    </Tag>
  );
};

Checkbox.defaultProps = defaultProps;

export default Checkbox;
