export const initialValues = {
  bank: '',
  bankAccount: '',
  userName: '',
  valueTransfer: '',
};

export const TERM_CONDITION = [
  {
    data: 'Nominal transfer keluar <b>tidak dapat melebihi Rp 200 juta</b>',
  },
  {
    data: 'Proses transfer akan memakan waktu kurang lebih 1-2 hari kerja',
  },
  {
    data: 'Transfer keluar hanya bisa 1 kali dalam 1 hari',
  },
  {
    data:
      'Transfer hanya bisa dilakukan ke rekening yang sesuai dengan nama yang tertera di KTP',
  },
];

export const BANK_LIST_ENDPOINT = '/bank';
export const TRANSFER_OUT_ENDPOINT = '/dashboard/transfer/out';
export const AMAR_BANK_CODE = '531';
