import React from 'react';
import { Formik } from 'formik';
import ReactMarkdown from 'react-markdown';

import { SEO } from 'modules/components/SEO';
import { Icon } from 'modules/components/icon';
import { Form, FormField } from 'modules/components/form';
import { Select } from 'modules/components/inputs';
import { Button } from 'modules/components/button';
import { Separator } from 'modules/components/separator';
import { SenyumkuBalanceAmount } from 'pages/dashboard/modules/components/senyumku-balance';
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from 'modules/components/modal';

import Banner from 'pages/dashboard/modules/components/banner/banner';
import Content from 'pages/dashboard/transfer/transfer-out/modules/components/content';

import useModalContext from 'modules/components/modal/modal.hook';
import useTransferOut from './modules/services/transfer-out.hook';
import useSenyumkuBalance from 'pages/dashboard/modules/components/senyumku-balance/modules/services/senyumku-balance.hook';
import { validationSchema } from 'pages/dashboard/transfer/transfer-out/modules/services/transfer-out.validation';

import { separatorThousand } from 'modules/utilities/add-separator-thousand.util';

import {
  initialValues,
  TERM_CONDITION,
} from 'pages/dashboard/transfer/transfer-out/modules/constants/transfer-out.const';
import {
  MIN_TRANSFER_OUT,
  MAX_TRANSFER_OUT,
} from 'modules/constants/product.const';
import { TRANSFER_FEE } from 'modules/constants/product.const';

import background from 'assets/images/bg-dashboard-sm.svg';
import IconArrowTransfer from 'assets/images/arrow-transfer.svg';
import './transfer-out.scss';

const styleHeaderBanner = {
  background,
  icon: IconArrowTransfer,
  title: 'Transfer Keluar',
  link: '/dashboard/transfer',
  idBanner: 'ButtonBackToTransfer',
};

const TransferOut = () => {
  const { openModal, closeModal } = useModalContext();
  const { senyumkuBalance } = useSenyumkuBalance();
  const { senyumku_balance } = senyumkuBalance;
  const {
    dataCustomer,
    handleTransferOutSubmit,
    setConfirmationForm,
    state,
    setValuesForm,
    nominalWithSeparator,
    totalCalculation,
  } = useTransferOut();

  const {
    listBank,
    dataConfirmationTransfer,
    bankName,
    isModalConfirmation,
  } = state;
  const { updated_date } = dataCustomer;

  const validatorOptions = {
    senyumku: senyumku_balance,
    minTransfer: MIN_TRANSFER_OUT,
    maxTransfer: MAX_TRANSFER_OUT,
  };

  return (
    <>
      <SEO title="Transfer Out" />
      <Banner {...styleHeaderBanner} />
      <div className="Section Section--product-light MinHeight">
        <div className="Container Padding">
          <div className="Flex">
            <Icon fa="info-circle" className="MarginRight-small" />
            <span>
              <strong>Informasi Penting!</strong>
            </span>
          </div>

          <ul className="TransferOut-info">
            {TERM_CONDITION.map(list => (
              <li>
                <ReactMarkdown source={list.data} escapeHtml={false} />
              </li>
            ))}
          </ul>
          <Separator />
          <h6 className="FontWeight-bold MarginBottom">Detail Bank</h6>
          <Formik
            validationSchema={validationSchema(validatorOptions)}
            initialValues={initialValues}
            onSubmit={setConfirmationForm}
            render={({ values, handleSubmit, isValid }) => {
              setValuesForm(values.valueTransfer);

              return (
                <Form onSubmit={handleSubmit}>
                  <FormField
                    id="DrpBankList"
                    name="bank"
                    inputTag={Select}
                    label="Nama Bank">
                    <option value="">Pilih Salah Satu</option>
                    {listBank.map(list => (
                      <option value={list.bank_id} key={list.bank_id}>
                        {list.bank_name}
                      </option>
                    ))}
                  </FormField>
                  <FormField
                    id="TxtDestinationAccountNumber"
                    name="bankAccount"
                    label="Nomor Rekening Tujuan"
                    type="tel"
                  />
                  <FormField
                    id="TxtRecipientName"
                    name="userName"
                    label="Nama Penerima"
                  />
                  <div className="Card BgColor-white MarginTop">
                    <div className="Card-body">
                      <div>
                        Saldo <span className="Color-primary">Amar Bank</span>
                      </div>

                      <div className="FontSize-small">
                        Rp
                        <span className="FontSize-large">
                          <strong>
                            <SenyumkuBalanceAmount />
                          </strong>
                        </span>
                      </div>
                      <div className="FontSize-2xsmall">
                        (Terakhir diperbarui : {updated_date})
                      </div>
                    </div>
                  </div>
                  <FormField
                    id="TxtNominalTransfer"
                    name="valueTransfer"
                    label="Nominal Transfer"
                    value={nominalWithSeparator}
                    type="tel"
                    className="MarginTop mb-none"
                  />
                  <div className="FontSize-10 Color-grey">
                    Belum dipotong biaya transfer sebesar Rp
                    {separatorThousand(TRANSFER_FEE)}
                  </div>

                  <Button
                    id="BtnTransferConfirmation"
                    type="submit"
                    disabled={!isValid}
                    className="MarginTop"
                    fullWidth
                    theme="primary"
                    onClick={openModal}>
                    Konfirmasi Transfer
                  </Button>

                  {isModalConfirmation && (
                    <Modal padding="none">
                      <ModalHeader className="FontWeight-bold">
                        Konfirmasi Transfer
                      </ModalHeader>
                      <ModalBody className="Padding-0">
                        <div className="Modal-info">
                          <Content
                            title="Nama Bank"
                            amount={bankName.bank_name}
                          />
                        </div>
                        <div className="Modal-info">
                          <Content
                            title="No. Rekening Tujuan"
                            amount={dataConfirmationTransfer.bankAccount}
                          />
                        </div>
                        <div className="Modal-info">
                          <Content
                            title="Nama Penerima"
                            amount={dataConfirmationTransfer.userName}
                          />
                        </div>

                        <div className="Container">
                          <div className="FontWeight-bold PaddingTop PaddingBottom">
                            Detail Transfer
                          </div>
                          <Content
                            title="Nominal Transfer"
                            amount={
                              dataConfirmationTransfer &&
                              'Rp' + dataConfirmationTransfer.valueTransfer
                            }
                          />
                          <Content
                            title="Biaya Transfer"
                            amount={`-(Rp${separatorThousand(TRANSFER_FEE)})`}
                          />
                          <Separator />
                          <div className="Grids">
                            <div className="Grid Grid-7">
                              <b>Total Diterima</b>
                            </div>
                            <div className="Grid Grid-5 Align-right">
                              <b>
                                Rp
                                {separatorThousand(totalCalculation)}
                              </b>
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                      <ModalFooter className="Padding">
                        <Button
                          id="BtnCancelTransfer"
                          className="MarginRight"
                          onClick={closeModal}
                          theme="primary"
                          fullWidth
                          outlined>
                          Batalkan
                        </Button>
                        <Button
                          id="BtnSubmitTransfer"
                          type="button"
                          theme="primary"
                          fullWidth
                          onClick={() =>
                            handleTransferOutSubmit(dataConfirmationTransfer)
                          }>
                          Transfer
                        </Button>
                      </ModalFooter>
                    </Modal>
                  )}
                </Form>
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TransferOut;
