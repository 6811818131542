import React from 'react';
import classNames from 'classnames';

import { Button } from 'modules/components/button';
import './button-calculation.scss';

const ButtonCalculation = props => {
  const {
    period,
    interestRate,
    handleCalculation,
    className,
    isNominalAboveOneBillion,
    ...attributes
  } = props;

  const cssClasses = {
    ButtonCalculation: classNames(
      `ButtonCalculation MarginRight MarginBottom-xsmall`,
      [isNominalAboveOneBillion && 'Width-25'],
      className,
    ),
    ButtonInterest: classNames(`ButtonCalculation-interest`),
  };

  return (
    <Button
      theme="green80"
      size="small"
      className={cssClasses.ButtonCalculation}
      outlined
      rounded
      onClick={handleCalculation}
      {...attributes}>
      {`${period} bulan`}
      {!isNominalAboveOneBillion && (
        <>
          &nbsp; |
          <span
            className={cssClasses.ButtonInterest}>{`${interestRate}%`}</span>
        </>
      )}
    </Button>
  );
};

export default ButtonCalculation;
