import React from 'react';
import classNames from 'classnames';

import { Field } from 'formik';
import { FormLabel, FormError, FormControl } from '../form';

import { Input, Checkbox } from '../inputs';
import { useRegisteredRef } from 'react-register-nodes';
import { removeFirstCapitalLetter } from 'modules/utilities/string.util';
import useFormField from './form-field.hook';

import './form.scss';

const defaultProps = {
  inputTag: Input,
  helper: null,
  formControlProps: null,
};

const FormField = props => {
  const {
    inputTag: InputTag,
    children,
    label,
    theme,
    render,
    helper,
    className,
    type,
    required,
    formControlProps,
    id,
    fsExclude,
    ...attributes
  } = props;

  const fieldRef = useRegisteredRef(attributes.name);
  const { handleChange, handleBlur } = useFormField(attributes);
  let classes = {};

  return (
    <Field
      {...attributes}
      render={({ field, form }) => {
        const isFieldError =
          form.errors[field.name] && form.touched[field.name];

        classes = {
          FormField: classNames(
            `Field`,
            {
              [`Form-field--${theme}`]: theme,
              'Field--danger': isFieldError,
            },
            className,
          ),
          InputTag: classNames({ 'fs-exclude': fsExclude }),
        };

        const onChange = e => handleChange(e, field);

        const onBlur = e => handleBlur(e, form, field);

        const inputProps = {
          ...field,
          label,
          isFieldError,
          onChange,
          onBlur,
        };

        const renderFormLabel = () => {
          if (InputTag === Checkbox) {
            return null;
          }

          return (
            <FormLabel htmlFor={field.name}>
              <div>
                {label}
                {required && <span className="Color-red">*</span>}
              </div>
            </FormLabel>
          );
        };

        const renderFormInput = () => {
          if (!render) {
            return (
              <>
                <InputTag
                  id={id}
                  {...inputProps}
                  {...attributes}
                  className={classes.InputTag}
                  type={type}>
                  {children}
                </InputTag>
              </>
            );
          }

          return render({ attributes });
        };

        const renderFormHelper = () => {
          if (!isFieldError && helper) {
            return helper({ attributes });
          }
          if (isFieldError) {
            return (
              <FormError
                name={field.name}
                id={`Error${removeFirstCapitalLetter(id)}`}
              />
            );
          }
          return null;
        };

        return (
          <div className={classes.FormField} ref={fieldRef}>
            {renderFormLabel()}
            <FormControl {...formControlProps}>{renderFormInput()}</FormControl>
            {renderFormHelper()}
          </div>
        );
      }}
    />
  );
};

FormField.defaultProps = defaultProps;

export default FormField;
