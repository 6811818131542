import React from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import { Header } from 'modules/components/header';
import { Icon } from 'modules/components/icon';
import { Form, FormField } from 'modules/components/form';
import { Select, InputMask } from 'modules/components/inputs';
import { Wizard } from 'modules/components/wizard';
import { Button } from 'modules/components/button';
import { Separator } from 'modules/components/separator';
import SecurityInfoCard from 'pages/modules/components/security-info-card';
import usePersonalData from 'pages/dashboard/registration/personal-data/modules/services/personal-data.hook';

import { ROUTES } from 'modules/constants/routes.const';
import {
  EDUCATION_FIELDS,
  NPWP_MASK,
} from './modules/constants/personal-data.const';
import { REGISTRATION_STEPS } from '../modules/constants/registration-steps.const';
import { validationSchemaPersonalData } from 'pages/dashboard/registration/personal-data/modules/services/personal-data.validation';

const PersonalData = () => {
  const history = useHistory();
  const {
    initialValues,
    HandleSubmitPersonalData,
    getUsernameAsPhoneNumber,
  } = usePersonalData();

  const validatorOptions = {
    phoneNumber: getUsernameAsPhoneNumber(),
  };

  return (
    <>
      <Header>
        <Icon
          id="BtnBack"
          onClick={() => history.push(ROUTES.account)}
          fa={['far', 'times-circle']}
          size="lg"
          theme="primary"
        />
      </Header>

      <SecurityInfoCard>
        Data ini diperlukan sebagai syarat dari OJK (Otoritas Jasa Keuangan).
        Kerahasiaan informasi kamu akan kami jaga
      </SecurityInfoCard>

      <div className="Container Flex-column FlexGrow-1 PaddingTop PaddingBottom">
        <div className="PaddingBottom PaddingTop">
          <Wizard activeStep={1} steps={REGISTRATION_STEPS} />
          <Separator className="MarginBottom-0" />
        </div>

        <div className="PaddingBottom-medium">
          <h6 className="Typography-bold16">Data Diri</h6>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaPersonalData(validatorOptions)}
          onSubmit={HandleSubmitPersonalData}
          enableReinitialize={true}
          render={() => {
            return (
              <Form className="Flex-column FlexGrow-1">
                <div className="Flex-column FlexGrow-1">
                  <FormField
                    inputTag={Select}
                    id="DrpLastEducation"
                    label="Pendidikan Terakhir"
                    name="last_education"
                    required>
                    <option value=""></option>
                    {EDUCATION_FIELDS.map(field => (
                      <option value={field} key={field}>
                        {field}
                      </option>
                    ))}
                  </FormField>
                  <FormField
                    id="TxtMotherName"
                    label="Nama Ibu Kandung"
                    name="mother_name"
                    fsExclude
                    required
                  />
                  <FormField
                    id="TxtRelativeName"
                    label="Nama Kerabat"
                    name="relative_name"
                    placeholder="Nama keluarga atau sanak saudara"
                    fsExclude
                    required
                  />
                  <FormField
                    id="TxtRelativePhoneNumber"
                    label="Nomor Telepon Kerabat"
                    name="relative_phone_number"
                    type="tel"
                    fsExclude
                    required
                  />
                  <FormField
                    id="TxtNpwp"
                    inputTag={InputMask}
                    label="NPWP"
                    name="npwp"
                    mask={NPWP_MASK}
                    fsExclude
                  />
                </div>
                <div className="PaddingTop-xlarge">
                  <p>
                    Pastikan semua data benar sebelum kamu lanjut ke langkah
                    berikutnya.
                  </p>
                  <Button
                    type="submit"
                    id="BtnPersonalDataNext"
                    theme="secondary"
                    fullWidth
                    className="MarginTop">
                    Simpan Data Diri
                  </Button>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </>
  );
};

export default PersonalData;
