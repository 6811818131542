export const GUIDE_SIDE_PHOTO = {
  title: 'Kenapa saya harus foto tampak samping?',
  description:
    'Pengambilan beberapa pose berfungsi untuk beberapa alasan keamanan : ',
  terms: [
    '1. Memastikan bahwa pengguna adalah orang asli',
    '2. Memastikan pengguna tidak menggunakan foto ataupun gambar orang lain',
  ],
};

export const ENDPOINT_VERIFICATION = '/email/verification';

export const ENDPOINT_KYC = '/customer/kyc';
