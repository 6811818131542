import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useApiInvoker } from '@tunaiku.npm/react-library/dist/cjs/modules/api-invoker';
import { SnackbarContext } from 'modules/components/snackbar';
import useStorageManager from 'modules/services/storage-manager.hook';
import useRegistration from 'pages/dashboard/registration/modules/services/registration.hook';
import {
  INITIAL_VALUES,
  ENDPOINT_EMPLOYMENT_DATA,
  WORK_TYPE_WITHOUT_EXTRA_FIELDS,
} from 'pages/dashboard/registration/employment-data/modules/constants/employment-data.const';
import { LOCAL_STORAGE } from 'modules/constants/local-storage.const';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';
import { ROUTES } from 'modules/constants/routes.const';

const useEmploymentData = () => {
  const history = useHistory();
  const { apiInvoker } = useApiInvoker({});
  const {
    getEncryptedDataFromLocalStorage,
    setEncryptedDataToLocalStorage,
  } = useStorageManager();
  const { openSnackbar } = useContext(SnackbarContext);
  const [initialValues, setInitialValues] = useState(null);
  const { getRegisId } = useRegistration();

  useEffect(() => {
    fetchEmploymentData();
  }, []);

  const fetchEmploymentData = () => {
    apiInvoker
      .get(`${ENDPOINT_EMPLOYMENT_DATA}?regs_id=${getRegisId()}`)
      .then(handleGetEmploymentDataSuccess)
      .catch(handleGetEmploymentDataError);
  };

  const handleGetEmploymentDataSuccess = res => {
    const { Data: data } = res.data;

    const {
      source_income,
      monthly_income,
      work_type,
      position,
      industrial_company,
      company_name,
    } = data;

    setDataEmployment(data);

    if (data) {
      return setInitialValues({
        ...INITIAL_VALUES,
        ...convertDataResponse({
          source_income,
          monthly_income,
          work_type,
          position,
          industrial_company,
          company_name,
        }),
      });
    }
    return setInitialValues(INITIAL_VALUES);
  };

  const convertDataResponse = ({
    source_income,
    monthly_income,
    work_type,
    position,
    industrial_company,
    company_name,
  }) => {
    const initialValuesAfterConvert = {
      source_income: !!source_income ? source_income : '',
      monthly_income: !!monthly_income ? monthly_income : '',
      work_type: !!work_type ? work_type : '',
      position: !!position ? position : '',
      industrial_company: !!industrial_company ? industrial_company : '',
      company_name: !!company_name ? company_name : '',
    };
    return initialValuesAfterConvert;
  };

  const handleGetEmploymentDataError = () =>
    openSnackbar({
      value: VALIDATION_MESSAGES.GLOBAL_ERROR_HANDLE,
    });

  const setDataEmployment = dataEmployment => {
    const getRegisData = getEncryptedDataFromLocalStorage(
      LOCAL_STORAGE.customerRegistration,
    );

    setEncryptedDataToLocalStorage(LOCAL_STORAGE.customerRegistration, {
      ...getRegisData,
      ...dataEmployment,
    });
  };

  const handleEmploymentRegistrationSubmit = values => {
    const dataUpdate = handleDataSubmit(values);
    setDataEmployment(values);
    submitEmploymentRegistration(dataUpdate);
  };

  const submitEmploymentRegistration = formData => {
    apiInvoker
      .post(ENDPOINT_EMPLOYMENT_DATA, formData)
      .then(handleEmploymentRegistrationSuccess)
      .catch(handleGetEmploymentDataError);
  };

  const handleEmploymentRegistrationSuccess = () =>
    history.push(ROUTES.identityData);

  const handleDataSubmit = values => {
    const hiddenExtraFields = WORK_TYPE_WITHOUT_EXTRA_FIELDS.includes(
      values.work_type,
    );
    let submitData = {
      ...values,
      regs_id: getRegisId(),
    };
    if (hiddenExtraFields) {
      submitData = {
        ...values,
        regs_id: getRegisId(),
        position: '',
        industrial_company: '',
        company_name: '',
      };
    }
    return submitData;
  };

  return {
    initialValues,
    handleEmploymentRegistrationSubmit,
  };
};

export default useEmploymentData;
