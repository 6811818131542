import React from 'react';
import classNames from 'classnames';

const RadioGroup = props => {
  // prettier-ignore
  const {
    tag: Tag,
		children,
		className,
		...attributes
	} = props;

  const classes = classNames(`RadioGroup`, className);

  return (
    <Tag {...attributes} className={classes}>
      {children}
    </Tag>
  );
};

RadioGroup.defaultProps = {
  tag: 'div',
};

export default RadioGroup;
