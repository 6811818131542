import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { ROUTES } from 'modules/constants/routes.const';
import senyumkuDepositoLogo from 'assets/images/amarbank-deposito-logo.svg';

import './header.scss';

const Header = ({ children, isDisableRedirect }) => {
  const history = useHistory();
  const authRoutes = ['/dashboard'];
  const isInsideAuthRoute = authRoutes.some(route =>
    history.location.pathname.includes(route),
  );

  const linkRedirectLogoSenyumku = () => {
    if (isInsideAuthRoute) {
      return ROUTES.account;
    } else {
      return ROUTES.home;
    }
  };

  const renderLogoSenyumku = () =>
    isDisableRedirect ? (
      <img
        className="Header-logo MarginTop-2xsmall MarginRight"
        src={senyumkuDepositoLogo}
        alt="senyumku depostio logo"
      />
    ) : (
      <Link id="ImgSenyumkuDeposito" to={linkRedirectLogoSenyumku()}>
        <img
          className="Header-logo MarginTop-small MarginRight"
          src={senyumkuDepositoLogo}
          alt="senyumku depostio logo"
        />
      </Link>
    );

  return (
    <div className="Header TopBar Padding-0">
      <div className="Container Flex JustifyContent-between">
        <div className="Flex AlignItems-center">{renderLogoSenyumku()}</div>
        <div className="Flex AlignItems-center">{children}</div>
      </div>
    </div>
  );
};

export default Header;
