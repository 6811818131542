import React from 'react';

const Content = ({ title, amount }) => {
  return (
    <div className="Grids">
      <div className="Grid Grid-7">{title}</div>
      <div className="Grid Grid-5 TextAlign-right">{amount}</div>
    </div>
  );
};

export default Content;
