import React from 'react';
import classNames from 'classnames';
import './select.scss';

const defaultProps = {
  tag: 'div',
  inputTag: 'select',
};

const Select = props => {
  /* prettier-ignore */
  const {
    tag: Tag,
    inputTag: InputTag,
    className,
    isLoading,
    children,
    isFieldError,
    ...attributes
  } = props;

  const cssClasses = classNames(
    `Select`,
    {
      'is-loading': isLoading,
      'Select--danger': !!isFieldError,
    },
    className,
  );

  attributes.disabled = attributes.disabled || isLoading;

  return (
    <Tag className={cssClasses}>
      <InputTag {...attributes}>{children}</InputTag>
    </Tag>
  );
};

Select.defaultProps = defaultProps;

export default Select;
