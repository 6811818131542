import React from 'react';
import classNames from 'classnames';
import { Icon } from 'modules/components/icon';
import { Input } from 'modules/components/inputs/input';

import 'assets/styles/components/_autocomplete.scss';

const Autocomplete = props => {
  /* prettier-ignore */
  const {
    id,
    data,
    className,
    children,
    isLoading,
    ...attributes
  } = props;

  const classes = classNames(`Input`, className);

  return (
    <div className="Autocomplete Width-100">
      <Input id={id} className={classes} {...attributes} />
      {children}
      {isLoading && (
        <Icon
          fa={['fas', 'spinner']}
          size="xs"
          theme="primary"
          className="AutocompleteSpinner"
          spin
        />
      )}
    </div>
  );
};

export default Autocomplete;
