import React from 'react';
import classNames from 'classnames';
import { Button } from 'modules/components/button';

import './live-chat-button.scss';

const LiveChatButton = props => {
  const {
    tag = 'div',
    theme = '',
    children,
    className,
    stickyBottom,
    ...attributes
  } = props;

  const showChatWidget = () => {
    if (window.zE) return window.zE(() => window.zE.activate());
  };

  const cssClasses = classNames(
    'LiveChatButton',
    {
      'LiveChatButton--stickyBottom': stickyBottom,
    },
    className,
  );

  return (
    <Button
      id="BtnLiveChat"
      tag={tag}
      theme={theme}
      className={cssClasses}
      onClick={showChatWidget}
      {...attributes}>
      {children}
    </Button>
  );
};

export default LiveChatButton;
