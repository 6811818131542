import React from 'react';
import { Link } from 'react-router-dom';
import { AMAR_BANK, OFFICE_INFORMATION } from 'modules/constants/general.const';
import { BRANDING_NAME } from 'modules/constants/product.const';

import chevronCirleRight from 'assets/images/shape.svg';
import amarLogo from 'assets/images/lg-amarbank-white-background.svg';

import './footer.scss';

const Footer = () => {
  return (
    <div className="Footer PaddingTop-medium PaddingBottom-medium">
      <div className="Container">
        <h6>{BRANDING_NAME}</h6>

        <div className="PaddingTop-xsmall">
          <Link
            to="/about-us"
            className="Color-white FontSize-small"
            id="LinkAboutUs">
            <img src={chevronCirleRight} alt="chevron right" />
            <span className="PaddingLeft">Tentang Kami</span>
          </Link>
        </div>

        <div className="PaddingTop-xsmall">
          <Link
            to="/contact-us"
            className="Color-white FontSize-small"
            id="LinkContactUs">
            <img src={chevronCirleRight} alt="chevron right" />
            <span className="PaddingLeft">Kontak Kami</span>
          </Link>
        </div>

        <div className="PaddingTop-xsmall">
          <Link
            to="/privacy-policy"
            className="Color-white FontSize-small"
            id="LinkPrivacyPolicy">
            <img src={chevronCirleRight} alt="chevron right" />
            <span className="PaddingLeft">Kebijakan Privasi</span>
          </Link>
        </div>

        <div className="PaddingTop-xsmall">
          <Link
            to="/terms-and-condition"
            id="LinkTermAndCondition"
            className="Color-white FontSize-small">
            <img src={chevronCirleRight} alt="chevron right" />
            <span className="PaddingLeft">Syarat & Ketentuan</span>
          </Link>
        </div>

        <section className="Section">
          <h6 className="PaddingTop">Alamat Kantor</h6>
          <div className="PaddingTop FontSize-small">
            {OFFICE_INFORMATION.address}
          </div>
        </section>

        <section className="Section">
          <div className="Grids">
            <div className="Grid Grid-5">
              <h6 className="PaddingTop-large">No. Telepon</h6>
              <p className="FontSize-small">{OFFICE_INFORMATION.number}</p>
            </div>
            <div className="Grid Grid-7">
              <h6 className="PaddingTop-large">Jam Operasional</h6>
              <p className="FontSize-small">24 Jam</p>
            </div>
          </div>
        </section>

        <div className="Flex AlignItems-center MarginTop-small">
          <img src={amarLogo} alt="amar logo" className="MarginRight-small" />
        </div>

        <hr />

        <div className="TextAlign-center MarginTop-medium FontSize-small">
          <strong>{AMAR_BANK}</strong> merupakan peserta penjaminan Lembaga
          Penjamin Simpanan (LPS), berizin dan diawasi Otoritas Jasa Keuangan
          (OJK) © 2023 {AMAR_BANK} Semua Hak Cipta Dilindungi.
        </div>
      </div>
    </div>
  );
};

export default Footer;
