import secure from 'assets/images/icon-terjamin.svg';
import flexible from 'assets/images/icon-cepat-mudah.svg';
import guaranteed from 'assets/images/icon-bunga-tinggi.svg';

import news1 from 'assets/images/news-line-1.png';
import news2 from 'assets/images/news-line-2.png';
import news3 from 'assets/images/news-line-3.png';

export const TOTAL_FUNDS = {
  amount: '1.21 Triliun',
  date: 'Maret 2023',
};

export const HOW_TO_REGISTER = [
  {
    id: 1,
    content: 'Daftar menggunakan nomor handphone dan email',
  },
  {
    id: 2,
    content:
      'Setelah daftar dan masuk ke akun, lengkapi data tambahan untuk dapat melakukan Transfer',
  },
  {
    id: 3,
    content: 'Pilih metode pembayaran dan ikuti instruksi Transfer',
  },
  {
    id: 4,
    content: 'Mulai deposito dan pantau pertumbuhannya secara rutin',
  },
];

export const HOW_TO_REGISTER_LITE = [
  {
    id: 1,
    content: 'Lanjut Daftar menggunakan akun Senyumku Lite',
  },
  {
    id: 2,
    content:
      'Setelah daftar dan masuk ke akun, lengkapi data tambahan untuk dapat melakukan Transfer',
  },
  {
    id: 3,
    content: 'Pilih metode pembayaran dan ikuti instruksi Transfer',
  },
  {
    id: 4,
    content: 'Mulai deposito dan pantau pertumbuhannya secara rutin',
  },
];

export const INFO_DEPOSITO = [
  {
    id: 'SldSecure',
    title: 'Investasimu pasti UNTUNG',
    img: secure,
    content: 'Saldo kamu pasti tumbuh setiap bulan',
  },
  {
    id: 'SldGuaranteed',
    title: 'Uangmu dijamin AMAN',
    img: guaranteed,
    content: 'Hanya bisa tarik saldo ke rekening atas nama kamu',
  },
  {
    id: 'SldFlexible',
    title: 'Proses daftar CEPAT & MUDAH',
    img: flexible,
    content: 'Daftar dan lengkapi data secara online (hanya 5 menit)',
  },
];

export const DEPOSITO_NEWS = [
  {
    id: 'LinkNewsOne',
    title:
      'Kinerja Membaik, Amar Bank (AMAR) Raup Laba Rp34,43 Miliar di Kuartal I-2023',
    img: news1,
    source: 'IDX Channel',
    link:
      'https://www.idxchannel.com/banking/kinerja-membaik-amar-bank-amar-raup-laba-rp3443-miliar-di-kuartal-i-2023',
  },
  {
    id: 'LinkNewsTwo',
    title: 'Amar Bank (AMAR) Geber Bisnis Bank Digital, Intip Strateginya',
    img: news2,
    source: 'IDX Channel',
    link:
      'https://www.idxchannel.com/banking/amar-bank-amar-geber-bisnis-bank-digital-intip-strateginya',
  },
  {
    id: 'LinkNewsThree',
    title:
      'Tampil dengan Logo Baru, Amar Bank Mengedepankan Kolaborasi Embedded Banking',
    img: news3,
    source: 'theiconomics.com',
    link:
      'https://www.theiconomics.com/market-expansion/tampil-dengan-logo-baru-amar-bank-mengedepankan-kolaborasi-embedded-banking/',
  },
];
