import { INTEREST_DEPOSITO } from 'modules/constants/product.const';

export const LIST_COMPARISON = [
  {
    id: 1,
    title: 'Bunga per Tahun',
    data: [`${INTEREST_DEPOSITO}%`, '4 - 5%', '4 - 7%'],
  },
  {
    id: 2,
    title: 'Setoran Minimum',
    data: ['Rp100.000', 'Rp1.000.000 - Rp10.000.000', 'Rp10.000 - Rp100.000'],
  },
  {
    id: 3,
    title: 'Informasi Biaya',
    data: [
      'Tidak Ada Biaya Admin, Materai atau Jasa.',
      'Biaya Admin dan Materai',
      'Biaya Jual Beli, Jasa Manajer Investasi, Bank Kustodian',
    ],
  },
  {
    id: 4,
    title: 'Proses Registrasi',
    data: ['Online', 'Ke Kantor Cabang atau Online', 'Online'],
  },
];

export const SOURCE_COMPARISON = [
  '*Sumber: OJK Statistik Perbankan Indonesia - April 2023',
  '**Sumber: Artikel OJK (https://sikapiuangmu.ojk.go.id/) tentang Jenis Investasi Reksadana',
];

export const START_INDICATOR = 1;

export const STEP_INDICATOR = 1;

export const END_INDICATOR = 4;
