import { normalizeString } from 'modules/utilities/string.util';

const useFormField = attributes => {
  const handleChange = (e, field) => {
    if (attributes.onChange) {
      return attributes.onChange(e);
    }

    return field.onChange(e);
  };

  const handleBlur = (e, form, field) => {
    form.setFieldTouched(field.name, true);

    if (!normalizeString) {
      return null;
    }

    if (form.values[e.target.name]) {
      form.setFieldValue(e.target.name, normalizeString(e.target.value));
    }
  };

  return {
    handleChange,
    handleBlur,
  };
};
export default useFormField;
