import * as Yup from 'yup';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';

import {
  NUMERICAL_WITH_PLUS_REGEX,
  NAME_WITH_SYMBOL_REGEX,
  NPWP_REGEX,
} from 'modules/constants/regex.const';
import { phoneNumberFormatValidator } from 'modules/validators/phone-number.validator';
import { formatPhoneNumber } from 'modules/utilities/phone-number.util';

export const validationSchemaPersonalData = (validatorOptions = {}) =>
  Yup.object().shape({
    last_education: Yup.string().required(
      VALIDATION_MESSAGES.FIELD_LAST_EDUCATION_NOT_SELECTED,
    ),
    mother_name: Yup.string()
      .required(VALIDATION_MESSAGES.FIELD_MOTHER_NAME_EMPTY)
      .matches(
        NAME_WITH_SYMBOL_REGEX,
        VALIDATION_MESSAGES.FIELD_MOTHER_NAME_WRONG_FORMAT,
      )
      .min(3, VALIDATION_MESSAGES.FIELD_MOTHER_NAME_MIN_LENGTH)
      .max(50, VALIDATION_MESSAGES.FIELD_MOTHER_NAME_MAX_LENGTH),

    relative_name: Yup.string()
      .required(VALIDATION_MESSAGES.FIELD_RELATIVE_NAME_EMPTY)
      .matches(
        NAME_WITH_SYMBOL_REGEX,
        VALIDATION_MESSAGES.FIELD_RELATIVE_NAME_WRONG_FORMAT,
      )
      .notOneOf(
        [Yup.ref('mother_name'), null],
        VALIDATION_MESSAGES.FIELD_RELATIVE_NAME_SAME_WITH_MOTHER_NAME,
      )
      .max(50, VALIDATION_MESSAGES.FIELD_RELATIVE_NAME_MAX_LENGTH),

    relative_phone_number: Yup.string()
      .required(VALIDATION_MESSAGES.FIELD_RELATIVE_PHONENUMBER_EMPTY)
      .matches(
        NUMERICAL_WITH_PLUS_REGEX,
        VALIDATION_MESSAGES.FIELD_RELATIVE_PHONENUMBER_WRONG_FORMAT,
      )
      .test(
        'samePhoneNumberValidation',
        VALIDATION_MESSAGES.FIELD_RELATIVE_PHONENUMBER_SAME_VALUE,
        value => {
          return (
            validatorOptions.phoneNumber !== formatPhoneNumber('+62', value)
          );
        },
      )
      .test(
        'phoneNumberValidation',
        VALIDATION_MESSAGES.FIELD_RELATIVE_PHONENUMBER_FORMAT_ID,
        phoneNumberFormatValidator,
      )
      .min(9, VALIDATION_MESSAGES.FIELD_RELATIVE_PHONENUMBER_BETWEEN_LENGTH)
      .max(14, VALIDATION_MESSAGES.FIELD_RELATIVE_PHONENUMBER_BETWEEN_LENGTH),
    npwp: Yup.string()
      .min(20, VALIDATION_MESSAGES.FIELD_NPWP_EXACT_LENGTH)
      .max(20, VALIDATION_MESSAGES.FIELD_NPWP_EXACT_LENGTH)
      .matches(NPWP_REGEX, VALIDATION_MESSAGES.FIELD_NPWP_WRONG_FORMAT),
  });
