import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Header } from 'modules/components/header';
import { Wizard } from 'modules/components/wizard';
import { Button } from 'modules/components/button';
import { ROUTES } from 'modules/constants/routes.const';
import { Icon } from 'modules/components/icon';
import { Checkbox } from 'modules/components/inputs';
import { Separator } from 'modules/components/separator';
import {
  BottomSheet,
  BottomSheetContext,
} from 'modules/components/bottom-sheet';
import SecurityInfoCard from 'pages/modules/components/security-info-card';
import PhotoVerificationButton from 'pages/dashboard/registration/photo-verification/photo-verification-state/modules/components/photo-verification-button';
import { PhotoVerificationStatusContext } from 'pages/dashboard/registration/photo-verification/modules/services/photo-verification.provider';
import usePhotoVerificationState from 'pages/dashboard/registration/photo-verification/photo-verification-state/modules/sevices/photo-verification-state.hook';

import { AMAR_BANK } from 'modules/constants/general.const';
import { GUIDE_SIDE_PHOTO } from 'pages/dashboard/registration/photo-verification/photo-verification-state/modules/constants/photo-verification-state.const';
import { REGISTRATION_STEPS } from 'pages/dashboard/registration/modules/constants/registration-steps.const';
import { ReactComponent as IconInfo } from 'assets/images/ic-info.svg';
import './photo-verification-state.scss';

const PhotoVerificationState = () => {
  const history = useHistory();
  const {
    onSubmitKycData,
    isDisabled,
    checkbox,
    setCheckbox,
    isSubmitting
  } = usePhotoVerificationState();
  const {
    isKtpPhotoUploaded,
    isSelfiePhotoUploaded,
    isLeftSelfiePhotoUploaded,
    isRightSelfiePhotoUploaded,
  } = useContext(PhotoVerificationStatusContext);
  const { openBottomSheet } = useContext(BottomSheetContext);

  return (
    <>
      <BottomSheet>
        <div className="FontSize-medium Color-primary FontWeight-bold MarginTop">
          {GUIDE_SIDE_PHOTO.title}
        </div>
        <div className="MarginTop MarginBottom-xsmall">
          {GUIDE_SIDE_PHOTO.description}
        </div>
        {GUIDE_SIDE_PHOTO.terms.map(list => (
          <div className="MarginTop-xsmall">{list}</div>
        ))}
      </BottomSheet>

      <Header isDisableRedirect />

      <SecurityInfoCard>
        Data ini diperlukan sebagai syarat dari OJK (Otoritas Jasa Keuangan).
        Kerahasiaan informasi kamu akan kami jaga
      </SecurityInfoCard>

      <div className="PaddingBottom PaddingTop">
        <Wizard activeStep={4} steps={REGISTRATION_STEPS} />
        <Separator className="MarginBottom-0" />
      </div>

      <div className="Flex-column FlexGrow-1">
        <div className="Flex-column FlexGrow-1">
          <div className="Container">
            <div className="PaddingBottom-medium">
              <Button
                size="small"
                id="LinkBackToEmploymentData"
                className="Padding-0 MarginBottom"
                onClick={() => history.push(ROUTES.identityData)}
                isText>
                <div className="Flex FontSize-xsmall">
                  <Icon fa="chevron-left" className="MarginRight-small" />
                  Kembali ke Data KTP
                </div>
              </Button>
              <h6 className="Typography-bold16">Foto KTP</h6>
            </div>

            <PhotoVerificationButton
              text="Foto KTP"
              description="Diperlukan untuk validasi identitas sesuai ketentuan OJK"
              id="BtnPhotoId"
              className="MarginBottom"
              type="ktp"
              isCompleted={isKtpPhotoUploaded}
              linkRedirect="/dashboard/registration/photo-verification/ktp"
            />

            {isKtpPhotoUploaded && (
              <>
                <h6 className="Typography-bold16 MarginBottom">Foto Diri</h6>
                <PhotoVerificationButton
                  text="Foto Tampak Depan"
                  description="Foto wajah kamu dari tampak depan"
                  id="BtnPhotoFront"
                  className="MarginBottom"
                  isCompleted={isSelfiePhotoUploaded}
                  linkRedirect="/dashboard/registration/photo-verification/selfie"
                />
              </>
            )}

            {isSelfiePhotoUploaded && (
              <>
                <h6 className="Typography-bold16 MarginBottom">
                  Foto Tampak Samping
                </h6>
                <PhotoVerificationButton
                  text="Foto Tampak Kanan"
                  description="Foto wajah kamu dari tampak kanan"
                  id="BtnPhotoRight"
                  className="MarginBottom"
                  isCompleted={isRightSelfiePhotoUploaded}
                  linkRedirect="/dashboard/registration/photo-verification/right-side"
                />
                <PhotoVerificationButton
                  text="Foto Tampak Kiri"
                  description="Foto wajah kamu dari tampak kiri"
                  id="BtnPhotoLeft"
                  className="MarginBottom"
                  isCompleted={isLeftSelfiePhotoUploaded}
                  linkRedirect="/dashboard/registration/photo-verification/left-side"
                />
                <Button
                  isText
                  size="small"
                  className="Float-right"
                  onClick={() => openBottomSheet({})}>
                  <IconInfo
                    className="Color-primary MarginRight-xsmall"
                    width="11"
                  />
                  Kenapa saya harus foto tampak samping?
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="BgColor-white MarginTop-2xlarge">
          <div className="Container Padding">
            <Checkbox
              id="ChkTermsAndCondition"
              label={`Saya menyetujui seluruh <a id="LinkTermsAndCondition" href="/dashboard/profile/terms-and-condition" 
                    target="_blank"> <u>Syarat dan Ketentuan</u></a> dari ${AMAR_BANK}
                  `}
              onChange={() => setCheckbox(!checkbox)}
            />
            <Button
              theme="secondary"
              id="BtnSaveAllData"
              className="MarginTop"
              onClick={onSubmitKycData}
              disabled={isDisabled || isSubmitting}
              fullWidth>
              Simpan Semua Data
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotoVerificationState;
