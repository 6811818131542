import React from 'react';
import LazyLoad from 'react-lazyload';

import DsJcb from 'assets/images/3-ds-jcb-logo.png';
import dsMc from 'assets/images/3DS-MC-LOGO.svg';
import dsVisa from 'assets/images/3DS-VISA-LOGO.svg';

const SecurityPayment = () => {
  return (
    <section className="MarginTop-large PaddingBottom MarginBottom-large">
      <div className="BgColor-white Padding">
        <div className="Container">
          <div className="TextAlign-center">
            <div className="Typography-bold14 MarginBottom-small">
              Keamanan Transaksi
            </div>
            <LazyLoad offset={100} once>
              <div className="Flex JustifyContent-center">
                <img src={dsVisa} alt="DS Visa" className="LogoPayment" />
                <img
                  src={dsMc}
                  alt="DS Master Card"
                  className="LogoPayment MarginLeft-large"
                />
                <img
                  src={DsJcb}
                  alt="3ds-logo"
                  className="LogoPayment MarginLeft-large"
                />
              </div>
            </LazyLoad>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SecurityPayment;
