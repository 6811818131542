import { removeSeparator } from 'modules/utilities/string.util';

export const minTransfer = (value, minAmountTransfer) => {
  const transformedValue = removeSeparator(value);
  const nominal = parseInt(transformedValue) >= minAmountTransfer;
  if (nominal) {
    return true;
  }
  return false;
};
