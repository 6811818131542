import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { SnackbarContext } from 'modules/components/snackbar';
import { useApiInvoker } from '@tunaiku.npm/react-library/dist/cjs/modules/api-invoker';
import useUserInformation from 'modules/services/user-information.hook';
import useStorageManager from 'modules/services/storage-manager.hook';
import useRegistration from 'pages/dashboard/registration/modules/services/registration.hook';
import { formatPhoneNumber } from 'modules/utilities/phone-number.util';
import {
  removeNonNumberCharacters,
  npwpFormated,
} from 'modules/utilities/string.util';

import {
  INITIAL_VALUES,
  ENDPOINT_PERSONAL_DATA,
} from 'pages/dashboard/registration/personal-data/modules/constants/personal-data.const';
import { LOCAL_STORAGE } from 'modules/constants/local-storage.const';
import { ROUTES } from 'modules/constants/routes.const';

import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';

const usePersonalData = () => {
  const history = useHistory();
  const [initialValues, setInitialValues] = useState(null);
  const { apiInvoker } = useApiInvoker({});
  const { openSnackbar } = useContext(SnackbarContext);
  const { getUsernameAsPhoneNumber } = useUserInformation();
  const { getRegisId } = useRegistration();
  const { setEncryptedDataToLocalStorage } = useStorageManager();

  useEffect(() => {
    fetchPersonalDataRegistration();
  }, []);

  const fetchPersonalDataRegistration = () => {
    apiInvoker
      .get(`${ENDPOINT_PERSONAL_DATA}?regs_id=${getRegisId()}`)
      .then(handleGetPersonalDataSuccess);
  };

  const handleGetPersonalDataSuccess = res => {
    const { Data: data } = res.data;
    const {
      npwp,
      relative_phone_number,
      last_education,
      mother_name,
      relative_name,
    } = data;

    const formData = {
      last_education: !!last_education ? last_education : '',
      mother_name: !!mother_name ? mother_name : '',
      relative_name: !!relative_name ? relative_name : '',
      relative_phone_number: !!relative_phone_number
        ? formatPhoneNumber('0', data.relative_phone_number)
        : '',
      npwp: !!npwp ? npwpFormated(data.npwp) : npwpFormated(''),
    };

    setEncryptedDataToLocalStorage(
      LOCAL_STORAGE.customerRegistration,
      formData,
    );

    if (data) {
      return setInitialValues({ ...INITIAL_VALUES, ...formData });
    }
    return setInitialValues(INITIAL_VALUES);
  };

  const HandleSubmitPersonalData = personalData => {
    const { npwp, relative_phone_number } = personalData;

    const formData = {
      ...personalData,
      relative_phone_number: formatPhoneNumber('+62', relative_phone_number),
      npwp: removeNonNumberCharacters(npwp),
      regs_id: getRegisId(),
    };

    submitPersonalData(formData);
  };

  const submitPersonalData = formData => {
    apiInvoker
      .post(ENDPOINT_PERSONAL_DATA, formData)
      .then(handlePersonalDataSuccess)
      .catch(handlePersonalDataError);
  };

  const handlePersonalDataSuccess = () => history.push(ROUTES.employmentData);

  const handlePersonalDataError = () => {
    openSnackbar({
      value: VALIDATION_MESSAGES.GLOBAL_ERROR_HANDLE,
    });
  };

  return {
    initialValues,
    HandleSubmitPersonalData,
    getUsernameAsPhoneNumber,
  };
};

export default usePersonalData;
