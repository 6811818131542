import * as Yup from 'yup';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';
import {
  NUMERICAL_REGEX,
  NAME_WITH_SYMBOL_REGEX,
  ALPHABETIC_WITH_SPACE_REGEX,
  NUMERICAL_WITH_DASH_REGEX,
} from 'modules/constants/regex.const';
import {
  MAX_HOME_ADDRESS_LENGTH,
  MAX_KTP_NAME_LENGTH,
  MAX_PLACE_OF_BIRTH_LENGTH,
  MAX_HAMLET_NUMBER_LENGTH,
  MAX_NEIGHBOURHOOD_NUMBER_LENGTH,
  MAX_SUB_DISTRICT_LENGTH,
  MIN_HOME_ADDRESS_LENGTH,
  MIN_PLACE_OF_BIRTH_LENGTH,
  MIN_SUB_DISTRICT_LENGTH,
  NIK_LENGTH,
} from '../constants/identity-data.const';
import { nikValidator } from '../validators/nik.validator';

export const validationSchema = Yup.object().shape({
  nik: Yup.string()
    .matches(NUMERICAL_REGEX, VALIDATION_MESSAGES.FIELD_KTPNUMBER_WRONG_FORMAT)
    .max(NIK_LENGTH, VALIDATION_MESSAGES.FIELD_KTPNUMBER_EXACT_LENGTH)
    .min(NIK_LENGTH, VALIDATION_MESSAGES.FIELD_KTPNUMBER_EXACT_LENGTH)
    .required(VALIDATION_MESSAGES.FIELD_KTPNUMBER_EMPTY)
    .test(
      'nikInvalidTest',
      VALIDATION_MESSAGES.FIELD_KTPNUMBER_WRONG_FORMAT,
      value => !nikValidator(value),
    ),
  name: Yup.string()
    .max(MAX_KTP_NAME_LENGTH, VALIDATION_MESSAGES.FIELD_KTPNAME_MAX_LENGTH)
    .matches(
      NAME_WITH_SYMBOL_REGEX,
      VALIDATION_MESSAGES.FIELD_KTPNAME_WRONG_FORMAT,
    )
    .required(VALIDATION_MESSAGES.FIELD_KTPNAME_EMPTY),
  place_of_birth: Yup.string()
    .matches(
      ALPHABETIC_WITH_SPACE_REGEX,
      VALIDATION_MESSAGES.FIELD_PLACE_OF_BIRTH_ONLY_STRING,
    )
    .min(
      MIN_PLACE_OF_BIRTH_LENGTH,
      VALIDATION_MESSAGES.FIELD_PLACE_OF_BIRTH_BETWEEN_LENGTH,
    )
    .max(
      MAX_PLACE_OF_BIRTH_LENGTH,
      VALIDATION_MESSAGES.FIELD_PLACE_OF_BIRTH_BETWEEN_LENGTH,
    )
    .required(VALIDATION_MESSAGES.FIELD_KTP_PLACE_OF_BIRTH_EMPTY),
  birth_date: Yup.string()
    .required(VALIDATION_MESSAGES.FIELD_KTP_BIRTH_DATE_EMPTY)
    .nullable(),
  gender: Yup.string().required(VALIDATION_MESSAGES.FIELD_KTP_GENDER_EMPTY),
  blood_type: Yup.string().required(
    VALIDATION_MESSAGES.FIELD_KTP_BLOOD_TYPE_EMPTY,
  ),
  home_address: Yup.string()
    .required(VALIDATION_MESSAGES.FIELD_KTP_HOME_ADDRESS_EMPTY)
    .min(
      MIN_HOME_ADDRESS_LENGTH,
      VALIDATION_MESSAGES.FIELD_KTP_HOME_ADDRESS_WRONG_LENGTH,
    )
    .max(
      MAX_HOME_ADDRESS_LENGTH,
      VALIDATION_MESSAGES.FIELD_KTP_HOME_ADDRESS_WRONG_LENGTH,
    ),
  hamlet_number: Yup.string()
    .matches(
      NUMERICAL_WITH_DASH_REGEX,
      VALIDATION_MESSAGES.FIELD_KTP_HAMLET_NUMBER_WRONG_FORMAT,
    )
    .required(VALIDATION_MESSAGES.FIELD_KTP_HAMLET_NUMBER_EMPTY)
    .max(
      MAX_HAMLET_NUMBER_LENGTH,
      VALIDATION_MESSAGES.FIELD_KTP_HAMLET_NUMBER_WRONG_FORMAT,
    ),
  neighbourhood_number: Yup.string()
    .matches(
      NUMERICAL_WITH_DASH_REGEX,
      VALIDATION_MESSAGES.FIELD_KTP_NEIGHBOURHOOD_NUMBER_WRONG_FORMAT,
    )
    .required(VALIDATION_MESSAGES.FIELD_KTP_NEIGHBOURHOOD_NUMBER_EMPTY)
    .max(
      MAX_NEIGHBOURHOOD_NUMBER_LENGTH,
      VALIDATION_MESSAGES.FIELD_KTP_RT_WRONG_FORMAT,
    ),
  urban_village: Yup.string().required(
    VALIDATION_MESSAGES.FIELD_URBAN_VILLAGE_EMPTY,
  ),
  sub_district: Yup.string()
    .required(VALIDATION_MESSAGES.FIELD_SUB_DISTRICT_EMPTY)
    .min(
      MIN_SUB_DISTRICT_LENGTH,
      VALIDATION_MESSAGES.FIELD_SUB_DISTRICT_BETWEEN_LENGTH,
    )
    .max(
      MAX_SUB_DISTRICT_LENGTH,
      VALIDATION_MESSAGES.FIELD_SUB_DISTRICT_BETWEEN_LENGTH,
    ),
  nationality: Yup.string().required(
    VALIDATION_MESSAGES.FIELD_KTP_NATIONALITY_EMPTY,
  ),
  religion: Yup.string().required(
    VALIDATION_MESSAGES.FIELD_RELIGION_NOT_SELECTED,
  ),
  marital_status: Yup.string().required(
    VALIDATION_MESSAGES.FIELD_KTP_MARITAL_STATUS_EMPTY,
  ),
  is_card_valid_for_life: Yup.bool().oneOf(
    [true, false],
    VALIDATION_MESSAGES.FIELD_TERMS_NOT_CHECKED,
  ),
  card_date_expired: Yup.string().when('is_card_valid_for_life', {
    is: val => val === false,
    then: Yup.string()
      .required(VALIDATION_MESSAGES.FIELD_KTP_CARD_DATE_EXPIRED_EMPTY)
      .nullable(),
  }),
});
