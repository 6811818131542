import React from 'react';
import LazyLoad from 'react-lazyload';

import { DEPOSITO_NEWS } from 'pages/landing/modules/constants/landing.const';

const SenyumkuNews = () => {
  return (
    <section className="PaddingTop-large">
      <div className="Container">
        <h6 className="FontWeight-bold PaddingBottom">Liputan Amar Bank</h6>
      </div>
      <div className="Card Card--elevated PaddingTop-0 PaddingBottom-0">
        <div className="Card-body">
          {DEPOSITO_NEWS.map(data => (
            <div className="Grids Border-bottom Padding" key={data.title}>
              <div className="Grid Grid-8 PaddingLeft-0">
                <a
                  href={data.link}
                  target="_blank"
                  rel="noreferrer"
                  id={data.id}
                  className="TextDecoration-none">
                  <div className="Typography-bold14 Color-dark">
                    {data.title}
                  </div>
                  <div className="FontSize-xsmall Color-grey60">
                    {data.source}
                  </div>
                </a>
              </div>
              <div className="Grid Grid-4 PaddingRight-0">
                <LazyLoad offset={100} once>
                  <img
                    src={data.img}
                    alt={data.title}
                    width="96"
                    height="64"
                    className="Margin-xsmall Float-right"
                  />
                </LazyLoad>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SenyumkuNews;
