import React from 'react';
import 'modules/components/arrow-down/arrow-down.scss';

const ArrowDown = () => {
  return (
    <svg className="Arrows">
      <path className="Arrow1" d="M0 0 L10 12 L20 0"></path>
      <path className="Arrow2" d="M0 20 L10 32 L20 20"></path>
    </svg>
  );
};

export default ArrowDown;
