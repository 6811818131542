export const NIK_LENGTH = 16;
export const MAX_KTP_NAME_LENGTH = 50;
export const MIN_PLACE_OF_BIRTH_LENGTH = 2;
export const MAX_PLACE_OF_BIRTH_LENGTH = 40;
export const MIN_HOME_ADDRESS_LENGTH = 3;
export const MAX_HOME_ADDRESS_LENGTH = 150;
export const MAX_HAMLET_NUMBER_LENGTH = 3;
export const MAX_NEIGHBOURHOOD_NUMBER_LENGTH = 3;
export const MIN_SUB_DISTRICT_LENGTH = 2;
export const MAX_SUB_DISTRICT_LENGTH = 40;

export const DATE_PLACEHOLDER = 'DD/MM/YYYY';
export const DATE_FORMAT = 'dd/MM/yyyy';

export const URBAN_VILLAGE_NOT_FOUND = 'Kelurahan tidak ditemukan';
export const URBAN_VILLAGE_NOT_FROM_SEARCH_RESULT =
  'Anda belum memilih kelurahan pada saat mengetik';
export const SUB_DISTRICT_MISMATCH =
  'Kecamatan tidak ditemukan, silahkan pilih kembali melalui kolom Kelurahan';

export const INITIAL_VALUES = {
  nik: '',
  name: '',
  place_of_birth: '',
  birth_date: '',
  gender: '',
  blood_type: '-',
  home_address: '',
  hamlet_number: '',
  neighbourhood_number: '',
  urban_village: '',
  sub_district: '',
  nationality: 'WNI',
  is_card_valid_for_life: true,
  card_date_expired: null,
  village_no_wincore: '',
  district_no_wincore: '',
  province_no_wincore: '',
  province: '',
  city: '',
  city_no_dati_wincore: '',
};

export const GENDER_OBJECT = {
  male: 'Laki-Laki',
  female: 'Perempuan',
};

export const GENDER_FIELDS = ['Laki-Laki', 'Perempuan'];

export const BLOOD_TYPE_FIELDS = ['A', 'B', 'AB', 'O'];

export const RELIGION_FIELDS = [
  'Katolik',
  'Kristen',
  'Islam',
  'Budha',
  'Hindu',
  'Lainnya',
];

export const MARITAL_STATUS_FIELDS = [
  'Belum Kawin',
  'Kawin',
  'Cerai Hidup',
  'Cerai Mati',
];

export const NATIONALITY_FIELDS = ['WNI', 'WNA'];

export const URBAN_VILLAGE_DEBOUNCE_TIME = 1000;

export const IDENTITY_DATA_ENDPOINT = '/customer/idCardData';

export const URBAN_VILLAGE_ENDPOINT = '/village?keyword=';

export const ERROR_CODE = {
  nikExist: 1,
  blacklist: 2,
};
