import React, { useRef } from 'react';

import { useOAuth } from '@tunaiku.npm/react-library/dist/cjs/modules/oauth';
import LazyLoad from 'react-lazyload';

import { SEO } from 'modules/components/SEO';
import { Button } from 'modules/components/button';
import { Footer } from 'modules/components/footer';
import { Header } from 'modules/components/header';
import { ArrowDown } from 'modules/components/arrow-down';
import { Separator } from 'modules/components/separator';
import { Calculation } from 'pages/modules/components/calculation';
import { FaqComponent } from 'pages/modules/components/faq';
import PaymentSupport from 'pages/landing/modules/components/payment-support';
import SecurityPayment from 'pages/landing/modules/components/security-payment';
import Product from 'pages/landing/modules/components/product';
import ProductComparison from 'pages/landing/modules/components/product-comparison';
import SenyumkuNews from 'pages/landing/modules/components/senyumku-news';
import HeroBannerVariant from 'pages/landing/modules/components/hero-banner-variant';
import LiveChatButton from 'pages/modules/components/live-chat-button';

import { DEFAULT_VALUE, BRANDING_NAME } from 'modules/constants/product.const';
import {
  HOW_TO_REGISTER,
  TOTAL_FUNDS,
  INFO_DEPOSITO,
} from 'pages/landing/modules/constants/landing.const';

import amarLogo from 'assets/images/amarbank-deposito-logo.svg';
import bestBankingLogo from 'assets/images/indonesia-bank-award-logo.png';
import liveChat from 'assets/images/live-chat-border.svg';

import 'pages/landing/landing.scss';

const LandingOriginal = ({ faqList }) => {
  const { login, register } = useOAuth();
  const productRef = useRef(null);

  const state = {
    nominal: DEFAULT_VALUE.amount,
    period: DEFAULT_VALUE.period,
    url: 'register',
    theme: 'secondary',
    idAction: 'BtnStartDeposit',
    content: {
      button: 'Mulai Deposito',
      product: 'Pilih Jenis Investasi',
      nominal: 'Nominal Deposito',
    },
  };

  return (
    <div className="Landing">
      <SEO title="Home" />
      <LiveChatButton className="Padding-0 Border-none" stickyBottom>
        <img src={liveChat} alt="live chat" />
      </LiveChatButton>
      <Header>
        <Button
          theme="secondary"
          size="small"
          id="BtnRegister"
          className="MarginRight PaddingLeft PaddingRight FontSize-10"
          onClick={register}>
          Daftar
        </Button>
        <Button
          theme="secondary"
          size="small"
          id="BtnLogin"
          className="PaddingLeft PaddingRight FontSize-10"
          outlined
          onClick={login}>
          Masuk
        </Button>
      </Header>

      <LazyLoad offset={100} once>
        <HeroBannerVariant ref={productRef} />
      </LazyLoad>

      <section className="BgColor-white">
        <div className="Container">
          <LazyLoad offset={100} once>
            <div className="LandingImage Flex JustifyContent-center">
              <div className="LandingImage--bank Flex Flex-column JustifyContent-center">
                <img src={amarLogo} alt="amar logo" />
              </div>
              <div className="LandingImage--award ">
                <img src={bestBankingLogo} alt="best banking" />
              </div>
            </div>
          </LazyLoad>
        </div>
      </section>

      <section className="PaddingBottom BgColor-white">
        <div className="Container">
          <Separator className="MarginTop-0" />
          <div className="TextAlign-center">
            <div>Total Dana yang telah dikelola Bank</div>
            <div className="Flex JustifyContent-center">
              <div className="Arrow-up MarginBottom-small MarginRight-xsmall"></div>
              <div className="FontSize-large">
                Rp
                <span className="FontSize-xlarge">
                  <strong>{TOTAL_FUNDS.amount}</strong>
                </span>
              </div>
            </div>
            <div className="FontSize-10">
              Terakhir di update : {TOTAL_FUNDS.date}
            </div>
            <LazyLoad offset={100} once>
              <ArrowDown />
            </LazyLoad>
          </div>
        </div>
      </section>

      <section className="MarginTop-large">
        <div className="Container">
          <h6 className="FontWeight-bold PaddingBottom">Detail Produk</h6>
          <Product />
        </div>
      </section>

      <section className="MarginTop" ref={productRef}>
        <div className="Container">
          <h6 className="FontWeight-bold PaddingTop-large PaddingBottom">
            Simulasi Deposito
          </h6>
          <div className="Card--elevated">
            <Calculation {...state} />
          </div>
        </div>
      </section>

      <section className="Section PaddingTop-large">
        <div className="Container">
          <h6 className="FontWeight-bold PaddingBottom">
            Amar Bank Deposito vs Produk Lain
          </h6>
          <ProductComparison />
        </div>
      </section>

      <section className="Section PaddingTop-large">
        <div className="Container">
          <h6 className="FontWeight-bold PaddingBottom">
            Kenapa {BRANDING_NAME}?
          </h6>
        </div>
        <div className="Card Card--elevated PaddingTop-0 PaddingBottom-0 MarginLeft MarginRight">
          <div className="Card-body">
            {INFO_DEPOSITO.map(data => (
              <div
                className="Grids Border-bottom PaddingBottom PaddingTop"
                key={data.title}>
                <div className="Grid Grid-xs-3">
                  <LazyLoad offset={100} once>
                    <img
                      src={data.img}
                      alt={data.title}
                      width="45"
                      height="45"
                      className="Margin-xsmall"
                    />
                  </LazyLoad>
                </div>
                <div className="Grid Grid-xs-9 MarginTop-xsmall">
                  <div className="Typography-bold14">{data.title}</div>
                  <div className="FontSize-xsmall">{data.content}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <SenyumkuNews />

      <section className="Section PaddingTop-large">
        <div className="Container">
          <h6 className="FontWeight-bold PaddingBottom">
            Cara Memulai Deposito
          </h6>
          <div className="Card Card--elevated">
            <div className="Card-body">
              {HOW_TO_REGISTER.map(list => {
                const { id, content } = list;
                return (
                  <div className="Grids Padding-xsmall" key={id}>
                    <div className="Grid Grid-xs-2">
                      <div className="Circle-number">{id}</div>
                    </div>
                    <div className="Grid Grid-xs-10">
                      <div className="FontSize-small">{content}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <PaymentSupport />

      <SecurityPayment />

      <FaqComponent className="MarginBottom-xlarge" faqList={faqList}>
        <h6 className="FontWeight-bold Color-green80 BgColor-white">FAQ</h6>
      </FaqComponent>
      <Footer />
    </div>
  );
};

export default LandingOriginal;
