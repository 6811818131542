import React from 'react';

import ReactDatePicker, { registerLocale } from 'react-datepicker';
import id from 'date-fns/locale/id';
import classNames from 'classnames';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.scss';

const defaultProps = {
  id: null,
  selectedDate: new Date(),
};

const DatePicker = props => {
  /* prettier-ignore */
  const {
    id: idAction,
    selectedDate,
    onChange,
    dateBlur,
    className,
    name,
    dateFormat,
    placeholder,
    customInput,
    ...attribute
  } = props;

  const classes = classNames('Input', 'DatePicker', className);

  registerLocale('id', id);

  return (
    <ReactDatePicker
      id={idAction}
      locale="id"
      name={name}
      dateFormat={dateFormat}
      onBlur={dateBlur}
      placeholderText={placeholder}
      className={classes}
      selected={!!selectedDate ? new Date(selectedDate) : selectedDate}
      onChange={onChange}
      showYearDropdown
      customInput={customInput}
      {...attribute}
    />
  );
};

DatePicker.defaultProps = defaultProps;

export default DatePicker;
