import React from 'react';
import classNames from 'classnames';
import { Icon } from 'modules/components/icon';
import useProductComparison from './modules/services/product-comparison.hook';
import { SOURCE_COMPARISON } from './modules/constants/product-comparison.const';
import { ReactComponent as AmarbankDeposito } from 'assets/images/amarbank-deposito-logo.svg';

import 'pages/landing/modules/components/product-comparison/product-comparison.scss';

const ProductComparison = () => {
  const {
    getDataComparison,
    setNextIndicator,
    setPrevIndicator,
  } = useProductComparison();

  const { data, id, title } = getDataComparison();

  const getClassName = param => {
    const classes = classNames(
      param === 0 && id !== 3 ? 'FontSize-large MarginTop-small' : null,
      param === 0 ? 'Color-green80 FontWeight-bold' : null,
      param === 1 ? 'BgColor-grey30 FontSize-xsmall' : null,
      param === 2 ? 'FontSize-xsmall' : null,
    );
    return classes;
  };

  return (
    <>
      <div className="WrapperCard">
        <div class="Grid Grid-5">
          <div className="GuardCard">
            <div className="Card Padding-0">
              <div className="Card-header BgColor-primary Color-white Padding-xsmall TextAlign-center">
                Produk
              </div>
              <div className="Card-body">
                <ul className="ListContent FontSize-xsmall TextAlign-center MarginTop-0">
                  <li>
                    <AmarbankDeposito />
                  </li>
                  <li className="BgColor-blue">Deposito Bank Lain*</li>
                  <li>Reksadana**</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="Grid Grid-7 PaddingLeft-small">
          <div className="GuardCard">
            <div className="Card Padding-0">
              <div className="Card-header Flex BgColor-primary Padding-xsmall Color-white JustifyContent-around">
                <Icon
                  fa={['fas', 'caret-left']}
                  size="lg"
                  onClick={setPrevIndicator}
                />
                {title}
                <Icon
                  fa={['fas', 'caret-right']}
                  size="lg"
                  onClick={setNextIndicator}
                />
              </div>
              <div className="Card-body Padding-0">
                <ul className="ListContent TextAlign-center Margin-0">
                  {data.map((list, key) => (
                    <li className={getClassName(key)}>{list}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {SOURCE_COMPARISON.map(list => (
        <div className="FontSize-2xsmall">
          <i>{list}</i>
        </div>
      ))}
    </>
  );
};

export default ProductComparison;
