import React from 'react';

import './switch-toggle.scss';

const defaultProps = {
  id: 'switch-toggle',
};

const SwitchToggle = props => {
  const {
    className,
    checked,
    checkedLabel,
    uncheckedLabel,
    onChange,
    id,
    ...attributes
  } = props;
  return (
    <div>
      <input
        onChange={onChange}
        checked={checked}
        type="checkbox"
        id={id}
        className="Switch-input"
        {...attributes}
      />
      <label htmlFor={id} className="Switch-label">
        {checked ? checkedLabel : uncheckedLabel}
      </label>
    </div>
  );
};

SwitchToggle.defaultProps = defaultProps;

export default SwitchToggle;
