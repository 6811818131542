export const INITIAL_VALUES = {
  last_education: '',
  mother_name: '',
  relative_name: '',
  relative_phone_number: '',
  npwp: '',
};

export const EDUCATION_FIELDS = [
  'Tidak Sekolah',
  'SD',
  'SMP',
  'SMA',
  'Diploma',
  'S1 (Sarjana)',
  'S2 (Magister)',
  'S3 (Doktoral)',
  'Profesor',
];

export const NPWP_MASK = '99.999.999.9-999.999';

export const ENDPOINT_PERSONAL_DATA = '/customer/personalData';
