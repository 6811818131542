import React from 'react';
import LazyLoad from 'react-lazyload';

import mandiri from 'assets/images/mandiri.svg';
import bni from 'assets/images/bni.svg';
import ovo from 'assets/images/ovo.svg';
import bri from 'assets/images/bri.svg';
import bca from 'assets/images/bca.svg';
import visa from 'assets/images/VISA-Logo.svg';
import masterCard from 'assets/images/MasterCard-Logo.svg';
import jcb from 'assets/images/JCB-Logo.svg';

const PaymentSupport = () => {
  return (
    <section className="MarginTop-large PaddingBottom MarginBottom-large">
      <div className="BgColor-white Padding">
        <div className="Container">
          <div className="TextAlign-center">
            <div className="Typography-bold14 MarginBottom-small">
              Menerima Transfer via
            </div>
            <LazyLoad offset={100} once>
              <div className="Flex JustifyContent-center">
                <img
                  src={bca}
                  alt="bca"
                  className="LogoPayment Padding-2xsmall MarginLeft-small"
                />
                <img
                  src={mandiri}
                  alt="mandiri"
                  className="LogoPayment Padding-2xsmall MarginLeft-small"
                />
                <img
                  src={bri}
                  alt="bri"
                  className="LogoPayment Padding-2xsmall MarginLeft-small"
                />
                <img
                  src={bni}
                  alt="bni"
                  className="LogoPayment Padding-2xsmall MarginLeft-small"
                />
                <img
                  src={ovo}
                  alt="ovo"
                  className="LogoPayment Padding-2xsmall MarginLeft-small"
                />
              </div>
              <div className="MarginTop">
                <img
                  src={visa}
                  alt="visa"
                  className="LogoPayment Padding-2xsmall"
                />
                <img
                  src={masterCard}
                  alt="mastercard"
                  className="LogoPayment MarginLeft-large MarginRight-large Padding-2xsmall"
                />
                <img
                  src={jcb}
                  alt="jcb"
                  className="LogoPayment Padding-2xsmall"
                />
              </div>
            </LazyLoad>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentSupport;
