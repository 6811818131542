import React, { forwardRef } from 'react';

import { Button } from 'modules/components/button';
import { scrollToRef } from 'modules/utilities/scroll.util';
import usePeriodList from 'pages/modules/services/period-list.hook';

import 'pages/landing/landing.scss';

const HeroBannerVariant = forwardRef((_props, ref) => {
  const { maxInterestRate } = usePeriodList();

  return (
    <section className="Section Section--primary PaddingTop PaddingBottom-medium Landing-heroBanner">
      <div className="Container TextAlign-center Color-white MarginTop-medium">
        <h1 className="Typography-heading24 Landing-heroBanner-text">
          Buka deposito bisa dari rumah
        </h1>
        <p className="FontSize-xsmall PaddingLeft PaddingRight PaddingTop PaddingBottom">
          Cukup <strong>daftar online</strong> , nikmati deposito dengan
          <strong> bunga hingga {maxInterestRate}%</strong> per tahun di Amar
          Bank Deposito
        </p>
        <div className="Flex JustifyContent-around">
          <Button
            theme="lighter"
            id="BtnProductView"
            className="Padding-small"
            outlined
            fullwidth
            onClick={() => scrollToRef(ref)}>
            Coba simulasi deposito
          </Button>
        </div>
      </div>
    </section>
  );
});

export default HeroBannerVariant;
