import { REMINDER_CONSTANTS } from 'pages/dashboard/top-up/top-up-reminder/modules/constant/top-up-reminder.const';

const useTimePicker = props => {
  const { hourValue, minuteValue, onChange } = props;
  const { hourMax, minuteMax, timeDefault } = REMINDER_CONSTANTS;

  const handleHourChange = e => {
    const { value } = e.target;
    let hour = value;

    if (value > hourMax) {
      hour = timeDefault;
    }
    if (value.length > 2) {
      hour = timeDefault;
    }
    onChange(hour, minuteValue);
  };

  const handleMinuteChange = e => {
    const { value } = e.target;
    let minute = value;

    if (value > minuteMax) {
      minute = timeDefault;
    }
    if (value.length > 2) {
      minute = timeDefault;
    }
    onChange(hourValue, minute);
  };

  return {
    hourValue,
    minuteValue,
    handleHourChange,
    handleMinuteChange,
  };
};

export default useTimePicker;
