import { useEffect, useReducer, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useOAuth } from '@tunaiku.npm/react-library/dist/cjs/modules/oauth';

import useResultCalculation from 'modules/services/result-calculation.hook';
import useStorageManager from 'modules/services/storage-manager.hook';
import { basicReducer } from 'modules/utilities/state.util';

import { nominalValidators } from 'modules/validators/nominal.validator';
import { SnackbarContext } from 'modules/components/snackbar';

import { inputThousandSeparator } from 'modules/utilities/input-thousand-separator.util';
import { removeSeparator } from 'modules/utilities/string.util';
import usePeriodList from 'pages/modules/services/period-list.hook';
import useAccount from 'pages/dashboard/account/modules/services/account.hook';

import { ONLY_NUMERICAL_REGEX } from 'modules/constants/regex.const';
import {
  PRODUCT_DEPOSITO,
  PRODUCT_CODE,
  DEFAULT_VALUE,
  MAXIMUM_TOTAL_BALANCE_WITH_NORMAL_INTEREST,
} from 'modules/constants/product.const';

import { LOCAL_STORAGE } from 'modules/constants/local-storage.const';
import { ROUTES } from 'modules/constants/routes.const';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';

const useCalculation = props => {
  const history = useHistory();
  const { nominal, period } = props;
  const { register } = useOAuth();
  const { setPlainDataToLocalStorage } = useStorageManager();
  const { dataCustomer } = useAccount();
  const location = useLocation();

  const defaultValues = {
    nominalDeposit: nominal,
    periodDeposit: period,
    interestRateDeposit: interestRate,
    product: PRODUCT_DEPOSITO,
    errors: '',
    nominalNonSeparator: '',
    modal: false,
    activeButton: DEFAULT_VALUE.period,
    isNominalAboveOneBillion: false,
    isOnChangeInputNominal: false,
  };

  const [state, setState] = useReducer(basicReducer, defaultValues);
  const {
    nominalDeposit,
    periodDeposit,
    nominalNonSeparator,
    interestRateDeposit,
    isNominalAboveOneBillion,
    isOnChangeInputNominal,
  } = state;
  const { openSnackbar } = useContext(SnackbarContext);

  const {
    periodInterestList,
    interestRate,
    isErrorGetPeriodList,
  } = usePeriodList(isNominalAboveOneBillion, periodDeposit);
  const { depositNominal } = useResultCalculation(state, isErrorGetPeriodList);

  const isInsideTopUp = location.pathname === ROUTES.topUp;

  useEffect(() => {
    setDefaultInterestRate();
  }, [interestRate]);

  useEffect(() => {
    setChangesCalculation();
  }, [periodDeposit, nominalDeposit]);

  useEffect(() => {
    checkValidationAboveOneBillion();
  }, [dataCustomer, isOnChangeInputNominal]);

  const setChangesCalculation = () => {
    setState({
      nominalNonSeparator: removeSeparator(nominalDeposit),
    });
  };

  const setDefaultInterestRate = () => {
    setState({
      interestRateDeposit: interestRate,
      periodDeposit: periodDeposit,
    });
  };

  const handleSelectedPeriod = (selectedPeriod, selectedInterest) => {
    setState({
      periodDeposit: selectedPeriod,
      interestRateDeposit: selectedInterest,
      activeButton: selectedPeriod,
    });
  };

  const handleChange = event => {
    const { value } = event.target;
    const valueOnlyNumber = value.replace(ONLY_NUMERICAL_REGEX, '');
    const maximalNominal = nominalValidators(valueOnlyNumber);

    setState({
      nominalDeposit: inputThousandSeparator(value, ONLY_NUMERICAL_REGEX),
      nominalNonSeparator: valueOnlyNumber,
      errors: maximalNominal,
      isNominalAboveOneBillion: checkValidationAboveOneBillion(valueOnlyNumber),
      isOnChangeInputNominal: true,
    });
  };

  const checkValidationAboveOneBillion = inputNominal => {
    const { total_balance } = dataCustomer;
    const currentNominalDeposit = inputNominal || nominalDeposit;
    const totalCurrentBalance =
      parseInt(total_balance) + parseInt(currentNominalDeposit);

    const isAboveOneBillionBasedOnInputNominal =
      parseInt(currentNominalDeposit) >
      MAXIMUM_TOTAL_BALANCE_WITH_NORMAL_INTEREST;

    const isAboveOneBillionIncludeTotalBalance =
      parseInt(total_balance) > MAXIMUM_TOTAL_BALANCE_WITH_NORMAL_INTEREST ||
      totalCurrentBalance > MAXIMUM_TOTAL_BALANCE_WITH_NORMAL_INTEREST;

    if (isInsideTopUp) {
      setState({
        isNominalAboveOneBillion: isAboveOneBillionIncludeTotalBalance,
      });
    }

    return isInsideTopUp
      ? isAboveOneBillionIncludeTotalBalance
      : isAboveOneBillionBasedOnInputNominal;
  };

  const setToLocalStorage = () => {
    setPlainDataToLocalStorage(
      LOCAL_STORAGE.resultCalculation,
      JSON.stringify({
        product: PRODUCT_CODE,
        result: depositNominal,
        period: periodDeposit.toString(),
        nominal: nominalNonSeparator,
        interestRate: interestRateDeposit,
        isNominalAboveOneBillion,
      }),
    );
  };

  const openSnackbarWhenServerError = () => {
    if (isErrorGetPeriodList) {
      openSnackbar({
        value: VALIDATION_MESSAGES.GLOBAL_ERROR,
      });
    }
  };

  const handleSubmit = () => {
    setToLocalStorage();

    if (props.url && !isErrorGetPeriodList) {
      if (props.url === 'register') {
        return register();
      } else {
        return history.push(props.url);
      }
    }
  };

  const handleToTopUpReminder = () => {
    setToLocalStorage();

    if (!isErrorGetPeriodList) {
      return history.push(ROUTES.topUpReminder);
    } else {
      openSnackbarWhenServerError();
    }
  };

  const isDisableButton = depositNominal === '0';

  const handleNominalDeposit = () => {
    return inputThousandSeparator(nominalDeposit, ONLY_NUMERICAL_REGEX);
  };

  return {
    state,
    handleChange,
    depositNominal,
    handleSubmit,
    isDisableButton,
    handleNominalDeposit,
    handleToTopUpReminder,
    periodInterestList,
    handleSelectedPeriod,
    isNominalAboveOneBillion,
    isInsideTopUp,
  };
};

export default useCalculation;
