import React from 'react';
import classNames from 'classnames';

const ResultCalculation = props => {
  const { nominalCalculation, isInsideTopUp } = props;
  const cssClasses = classNames(
    `MarginTop`,
    isInsideTopUp ? 'MarginBottom' : 'PaddingTop-0 PaddingBottom-0',
  );

  return (
    <div className={cssClasses}>
      <div className="Card-body">
        <div className="Typography-regular16">
          <div>
            <strong>Estimasi Saldo di Akhir Periode</strong>
            <div className="FontSize-10">*belum termasuk pajak</div>
          </div>
          <div className="Typography-heading24" id="TxtValue">
            Rp{nominalCalculation}.-
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultCalculation;
