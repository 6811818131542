export const MENU_PROFILE = [
  [
    {
      name: 'Kebijakan Privasi',
      link: '/dashboard/profile/privacy-policy',
      id: 'TabMenuPrivacyPolicy',
    },
    {
      name: 'Syarat & Ketentuan',
      link: '/dashboard/profile/terms-and-condition',
      id: 'TabMenuTermAndCondition',
    },
  ],
  [
    {
      name: 'Tentang Kami',
      link: '/dashboard/profile/about-us',
      id: 'TabMenuAboutUs',
    },
    {
      name: 'Kontak Kami',
      link: '/dashboard/profile/contact-us',
      id: 'TabMenuContactUs',
    },
    {
      name: 'Baca FAQ',
      link: '/dashboard/faq',
      id: 'TabMenuFaq',
    },
  ],
];

export const MENU_TRANSFER = [
  { name: 'Top Up', link: '/dashboard/transfer/topup', id: 'TabMenuTopUp' },
  {
    name: 'Transfer Keluar',
    link: '/dashboard/transfer/transfer-out',
    id: 'TabMenuTransferOut',
  },
  {
    name: 'Transfer Flex ke Max',
    link: '/dashboard/transfer/transfer-max',
    id: 'TabMenuTransferMax',
  },
];

export const DATE_PLACEHOLDER = 'DD/MM/YYYY';
export const CHECK_USER_ALREADY_EXISTING_ENDPOINT = '/registrants/login';
export const REGISTER_USER_ENDPOINT = '/registrants/register';
export const REQUEST_OTP_ENDPOINT = '/otp/request';
