import { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { parseISO } from 'date-fns';
import axios from 'axios';

import { SnackbarContext } from 'modules/components/snackbar';
import { debounce } from 'modules/utilities/debounce.util';
import { getDateMonthYearFormat } from 'modules/utilities/date.util';

import { useApiInvoker } from '@tunaiku.npm/react-library/dist/cjs/modules/api-invoker';
import useStorageManager from 'modules/services/storage-manager.hook';
import useRegistration from 'pages/dashboard/registration/modules/services/registration.hook';

import { LOCAL_STORAGE } from 'modules/constants/local-storage.const';
import { ROUTES } from 'modules/constants/routes.const';
import {
  IDENTITY_DATA_ENDPOINT,
  INITIAL_VALUES,
  GENDER_OBJECT,
  URBAN_VILLAGE_DEBOUNCE_TIME,
  URBAN_VILLAGE_NOT_FOUND,
  URBAN_VILLAGE_NOT_FROM_SEARCH_RESULT,
  ERROR_CODE,
  SUB_DISTRICT_MISMATCH,
} from 'pages/dashboard/registration/identity-data/modules/constants/identity-data.const';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';
import { API } from 'modules/env';
import { addZeroPadding } from 'modules/utilities/string.util';

const useIdentityData = () => {
  const history = useHistory();
  const { apiInvoker } = useApiInvoker({});
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES);
  const [urbanVillage, setUrbanVillage] = useState({
    isRequestUrbanVillage: false,
    urbanVillageResults: [],
    errorMessage: '',
  });

  const [
    chosenUrbanVillageAndSubDistrict,
    setChosenUrbanVillageAndSubDistrict,
  ] = useState({});
  const { openSnackbar } = useContext(SnackbarContext);
  const {
    getEncryptedDataFromLocalStorage,
    setEncryptedDataToLocalStorage,
  } = useStorageManager();
  const { getRegisId } = useRegistration();

  useEffect(() => {
    fetchIdentityData();
  }, []);

  const fetchIdentityData = () => {
    apiInvoker
      .get(`${IDENTITY_DATA_ENDPOINT}?regs_id=${getRegisId()}`)
      .then(getIdentityDataSuccess);
  };

  const getIdentityDataSuccess = res => {
    const { Data: data } = res.data;

    const {
      blood_type,
      gender,
      hamlet_number,
      home_address,
      is_card_valid_for_life,
      marital_status,
      name,
      nationality,
      neighbourhood_number,
      nik,
      place_of_birth,
      religion,
      sub_district,
      urban_village,
      birth_date,
      card_date_expired,
    } = data;

    if (data) {
      const identityData = {
        ...convertDataResponse({
          blood_type,
          gender,
          hamlet_number,
          home_address,
          marital_status,
          name,
          nationality,
          neighbourhood_number,
          nik,
          place_of_birth,
          religion,
          sub_district,
          urban_village,
          birth_date,
          card_date_expired,
          is_card_valid_for_life,
        }),
      };
      setDataIdentityToLocalStorage(identityData);

      return setInitialValues({ ...INITIAL_VALUES, ...identityData });
    }
    return setInitialValues(INITIAL_VALUES);
  };

  const convertDataResponse = ({
    blood_type,
    gender,
    hamlet_number,
    home_address,
    marital_status,
    name,
    nationality,
    neighbourhood_number,
    nik,
    place_of_birth,
    religion,
    sub_district,
    birth_date,
    urban_village,
    card_date_expired,
  }) => {
    const initialValuesAfterConvert = {
      blood_type: !!blood_type ? blood_type : '',
      gender: !!gender ? gender : '',
      hamlet_number: !!hamlet_number ? hamlet_number : '',
      home_address: !!home_address ? home_address : '',
      marital_status: !!marital_status ? marital_status : '',
      name: !!name ? name : '',
      nationality: !!nationality ? nationality : '',
      neighbourhood_number: !!neighbourhood_number ? neighbourhood_number : '',
      nik: !!nik ? nik : '',
      place_of_birth: !!place_of_birth ? place_of_birth : '',
      religion: !!religion ? religion : '',
      sub_district: !!sub_district ? sub_district : '',
      urban_village: !!urban_village ? urban_village : '',
      birth_date: !!birth_date ? parseISO(birth_date) : birth_date,
      card_date_expired: !!card_date_expired ? parseISO(birth_date) : '',
    };

    return initialValuesAfterConvert;
  };

  const subDistrictFromLocalStorage = getEncryptedDataFromLocalStorage(
    LOCAL_STORAGE.customerRegistration,
  );

  const setDataIdentityToLocalStorage = param => {
    const getRegisData = getEncryptedDataFromLocalStorage(
      LOCAL_STORAGE.customerRegistration,
    );

    setEncryptedDataToLocalStorage(LOCAL_STORAGE.customerRegistration, {
      ...getRegisData,
      ...param,
    });
  };

  const handleIdentityDataSubmit = (values, actions) => {
    setDataIdentityToLocalStorage(values);

    const tmpFormData = {
      ...values,
      regs_id: getRegisId(),

      birth_date: getDateMonthYearFormat(values.birth_date),
    };

    const { card_date_expired, ...formData } = tmpFormData;

    if (!tmpFormData.is_card_valid_for_life) {
      formData.card_date_expired = getDateMonthYearFormat(card_date_expired);
    }
    if (isUrbanVillageAndSubDistrictNotFromSearchResult(values)) {
      return setUrbanVillage({
        ...urbanVillage,
        errorMessage: URBAN_VILLAGE_NOT_FROM_SEARCH_RESULT,
      });
    }
    if (isSubDistrictMismatch(values)) {
      return actions.setFieldError('sub_district', SUB_DISTRICT_MISMATCH);
    }
    submitIdentityData(formData, actions);
  };

  const isUrbanVillageAndSubDistrictNotFromSearchResult = values =>
    !!urbanVillage.errorMessage &&
    chosenUrbanVillageAndSubDistrict.chosenUrbanVillage !==
      values.urban_village;

  const isSubDistrictMismatch = values =>
    chosenUrbanVillageAndSubDistrict.chosenSubDistrict !==
      values.sub_district &&
    subDistrictFromLocalStorage.sub_district !== values.sub_district;

  const submitIdentityData = (formData, actions) => {
    apiInvoker
      .post(IDENTITY_DATA_ENDPOINT, formData)
      .then(handleIdentityRegistrationSuccess)
      .catch(handleIdentityRegistrationError(actions));
  };

  const handleIdentityRegistrationSuccess = () =>
    history.push(ROUTES.photoVerification);

  const handleIdentityRegistrationError = actions => err => {
    const { Code } = err.response.data.Data;

    switch (Code) {
      case ERROR_CODE.nikExist: {
        return actions.setErrors({
          nik: VALIDATION_MESSAGES.REGISTRATION_ERROR_KTP_ALREADY_USED,
        });
      }
      case ERROR_CODE.blacklist: {
        return history.push('/dashboard/rejected-national-blacklist');
      }
      default: {
        return openSnackbar({
          value: VALIDATION_MESSAGES.REGISTRATION_ERROR_UNDEFINED,
        });
      }
    }
  };

  const onChangeNIKField = formik => event => {
    const { value } = event.target;
    formik.setFieldValue('nik', event.target.value);
    if (value.length === 16) {
      const dateKTP = value.substr(6, 2);
      const monthKTP = value.substr(8, 2);
      const yearKTP = value.substr(10, 2);
      const yearWithPrefix =
        parseInt(yearKTP) > 50 ? `19${yearKTP}` : `20${yearKTP}`;
      const gender =
        parseInt(dateKTP) - 40 > 0 ? GENDER_OBJECT.female : GENDER_OBJECT.male;
      const dateBasedOnGender =
        gender === GENDER_OBJECT.female ? dateKTP - 40 : dateKTP;

      const formattedBirthDate = new Date(
        `${yearWithPrefix}/${monthKTP}/${dateBasedOnGender}`,
      );

      if (formattedBirthDate.getTime()) {
        formik.setFieldValue('birth_date', formattedBirthDate);
        formik.setFieldValue('gender', gender);
      }
    }
  };

  const _onDebounce = useRef(
    debounce(
      urbanVillageValue => requestUrbanVillageList(urbanVillageValue),
      URBAN_VILLAGE_DEBOUNCE_TIME,
    ),
  ).current;

  const requestUrbanVillageList = async urbanVillageValue => {
    const response = await axios.get(
      `${API.REGION_URL}registration?search=villages_name&searchvalue=${urbanVillageValue}`,
    );

    const mappedResponse = response.data.map(item => {
      return {
        VillageName: item._source.villages_name,
        DistrictName: item._source.district_name,
        VillageNoWincore: item._source.villages_no_wincore,
        DistrictNoWincore: item._source.district_no_wincore,
        Province: item._source.province_name,
        ProvinceNoWincore: item._source.province_no_wincore,
        City: item._source.city_name,
        CityNoWincore: item._source.city_no_wincore,
        CityDatiNoWincore: item._source.city_no_dati_wincore,
        PostalCode: item._source.postal_code,
      };
    });

    if (!!mappedResponse.length > 0) {
      setUrbanVillage({
        errorMessage: '',
        isRequestUrbanVillage: false,
        urbanVillageResults: mappedResponse,
      });
    } else {
      setUrbanVillage({
        ...urbanVillage,
        errorMessage: URBAN_VILLAGE_NOT_FOUND,
        isRequestUrbanVillage: false,
      });
    }
  };

  const onClickUrbanVillage = (formik, urbanVillageResult) => {
    formik.setFieldValue('urban_village', urbanVillageResult.VillageName);
    formik.setFieldValue('sub_district', urbanVillageResult.DistrictName);
    formik.setFieldValue('province', urbanVillageResult.Province);
    formik.setFieldValue(
      'province_no_wincore',
      addZeroPadding(String(urbanVillageResult.ProvinceNoWincore)),
    );
    formik.setFieldValue('city', urbanVillageResult.City);
    formik.setFieldValue(
      'city_no_wincore',
      addZeroPadding(String(urbanVillageResult.CityNoWincore)),
    );
    formik.setFieldValue(
      'city_no_dati_wincore',
      addZeroPadding(String(urbanVillageResult.CityDatiNoWincore)),
    );
    formik.setFieldValue(
      'village_no_wincore',
      addZeroPadding(String(urbanVillageResult.VillageNoWincore)),
    );
    formik.setFieldValue(
      'district_no_wincore',
      addZeroPadding(String(urbanVillageResult.DistrictNoWincore)),
    );
    formik.setFieldValue('postal_code', String(urbanVillageResult.PostalCode));

    setChosenUrbanVillageAndSubDistrict({
      chosenUrbanVillage: urbanVillageResult.VillageName,
      chosenSubDistrict: urbanVillageResult.DistrictName,
    });
    setUrbanVillage({
      ...urbanVillage,
      urbanVillageResults: [],
    });
  };

  const onChangeUrbanVillage = formik => evt => {
    formik.setFieldValue('urban_village', evt.target.value);
    const trimmedValue = evt.target.value.trim();

    setUrbanVillage({
      ...urbanVillage,
      errorMessage: '',
      urbanVillageResults: [],
    });

    if (trimmedValue.length <= 1) return;

    setUrbanVillage({
      ...urbanVillage,
      isRequestUrbanVillage: true,
    });

    _onDebounce(trimmedValue);
  };

  const onChangeSubDistrict = formik => evt => {
    formik.setFieldValue('sub_district', evt.target.value);
  };

  return {
    initialValues,
    handleIdentityDataSubmit,
    onChangeNIKField,
    onClickUrbanVillage,
    onChangeUrbanVillage,
    urbanVillage,
    setUrbanVillage,
    onChangeSubDistrict,
  };
};

export default useIdentityData;
