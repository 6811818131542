export const INITIAL_VALUES = {
  source_income: '',
  monthly_income: '',
  work_type: '',
  position: '',
  industrial_company: '',
  company_name: '',
};

export const SOURCE_INCOME_FIELD = [
  'Gaji',
  'Pendapatan atas Jasa',
  'Pendapatan dari Keluarga (Suami/Istri/Orang Tua)',
  'Pemasukan dari Usaha',
  'Hasil Investasi',
  'Warisan',
  'Dana Hibah',
  'Hadiah',
  'Dana Pinjaman',
];

export const MONTHLY_INCOME_FIELD = [
  'Dibawah 3.000.000',
  '3.000.001 - 5.000.000',
  '5.000.001 - 10.000.000',
  '10.000.001 - 20.000.000',
  '20.000.001 - 30.000.000',
  '30.000.001 - 50.000.000',
  '50.000.001 - 100.000.000',
  'Diatas 100 juta',
];

// - If user choose option 1,2,3,4, show 3 more fields: ""Jabatan, Industri Perusahaan, Nama Perusahaan"""
export const WORK_TYPE_FIELD = [
  'Pegawai Swasta',
  'Pekerja Lepas (Jasa/Freelance/Kontrak)',
  'Pegawai Negeri Sipil',
  'Wiraswasta / Pengusaha',
  'Militer / Polisi',
  'Pensiunan',
  'Ibu Rumah Tangga',
  'Pelajar / Mahasiswa',
  'Tidak Bekerja',
];

export const POSITION_FIELD = [
  'Karyawan Kontrak (Jasa/Freelance)',
  'Staff',
  'Supervisor',
  'Manajer',
  'Kepala',
  'Direktur',
  'Pemilik Usaha',
  'Tidak Ada Jabatan',
];

export const INDUSTRIAL_COMPANY_FIELD = [
  'Agrikultur',
  'Bank',
  'Biro Travel',
  'Casino/Club',
  'Dealer Mobil/Motor',
  'Distributor',
  'Edukasi',
  'Ekspor-impor',
  'Electricity/Power Supply',
  'Emas',
  'Farmasi',
  'Hiburan',
  'Hotel/Tourism',
  'Industri Barang Antik',
  'Industri Kayu/Furnitur/Mabel',
  'Industri Kimia',
  'Industri Kulit',
  'Industri Makanan',
  'Jasa Akunting',
  'Kecantikan',
  'Kehutanan',
  'Kertas',
  'Konstruksi',
  'Konsultan',
  'Konsultan Finansial',
  'Kuliner',
  'Layanan Parkir',
  'Layanan Pengacara',
  'Media',
  'Minimarket/Department Store',
  'Notaris',
  'Otomotif',
  'PDAM',
  'Pembuat Akta Tanah',
  'Pemerintahan',
  'Pengembang/Agen Property',
  'Percetakan',
  'Perdagangan',
  'Perikanan & Kelautan',
  'Perkebunan',
  'Pertambangan',
  'Pertanian',
  'Pom Bensin',
  'Restoran/Kafe',
  'Rumah Sakit/Layanan Kesehatan',
  'Seni & Budaya',
  'Teknologi & Informasi',
  'Tekstil',
  'Telekomunikasi',
  'Transportasi Publik',
  'Yayasan',
  'Lainnya',
];

export const ENDPOINT_EMPLOYMENT_DATA = '/customer/workData';

export const WORK_TYPE_WITHOUT_EXTRA_FIELDS = [
  '',
  'Militer / Polisi',
  'Tidak Bekerja',
  'Ibu Rumah Tangga',
  'Pelajar / Mahasiswa',
  'Pensiunan',
];
