import { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useApiInvoker } from '@tunaiku.npm/react-library/dist/cjs/modules/api-invoker';
import useStorageManager from 'modules/services/storage-manager.hook';
import { SnackbarContext as Snackbar } from 'modules/components/snackbar';
import { copyToClipboard } from 'modules/utilities/copy-to-clipboard.util';

import { ROUTES } from 'modules/constants/routes.const';
import {
  DASHBOARD_ACCOUNT_ENDPOINT,
  ACCOUNT_NUMBER_CLIPBOARD_MESSAGE,
} from '../constants/account.const';
import { LOCAL_STORAGE } from 'modules/constants/local-storage.const';

const useAccount = () => {
  const history = useHistory();
  const location = useLocation();
  const { openSnackbar } = useContext(Snackbar);
  const { apiInvoker, getErrorStatus } = useApiInvoker({});
  const [dataCustomer, setDataCustomer] = useState({});
  const { getEncryptedDataFromLocalStorage } = useStorageManager();

  const isOnLandingPage = location.pathname === ROUTES.home;

  useEffect(() => {
    !isOnLandingPage && getDataAccount();
  }, []);

  const getDataAccount = () => {
    if (getCustomerDataFromLocalStorage()) {
      if (getCustomerId()) {
        apiInvoker
          .get(DASHBOARD_ACCOUNT_ENDPOINT)
          .then(handleAccountSuccess)
          .catch(handleAccountError);
      }
    } else {
      return history.push(ROUTES.dashboard);
    }
  };

  const getCustomerId = () => {
    const customerLocalStorage = getCustomerDataFromLocalStorage();
    return customerLocalStorage.customer_id;
  };

  const copyAccountNumberToClipboard = () => {
    copyToClipboard(dataCustomer.account_number);
    openSnackbar({ value: `${ACCOUNT_NUMBER_CLIPBOARD_MESSAGE}` });
  };

  const getCustomerDataFromLocalStorage = () =>
    getEncryptedDataFromLocalStorage(LOCAL_STORAGE.customer);

  const handleAccountError = err => {
    const errorStatus = getErrorStatus(err);
    const { isUnauthorized } = errorStatus;

    if (isUnauthorized) {
      return history.push(ROUTES.login);
    }
  };

  const handleAccountSuccess = res => {
    const { Data: data } = res.data;
    setDataCustomer(data);
  };

  return {
    dataCustomer,
    copyAccountNumberToClipboard,
  };
};

export default useAccount;
