import React from 'react';
import Content from 'pages/modules/components/product/content';
import { PRODUCT_DEPOSITO } from 'modules/constants/product.const';

const Product = () => {
  return (
    <div className="Section">
      <div className="Card Card--elevated">
        <div className="Card-body">
          <Content type={PRODUCT_DEPOSITO} />
        </div>
      </div>
    </div>
  );
};

export default Product;
