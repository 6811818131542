import React from 'react';
import classNames from 'classnames';
import { Button } from 'modules/components/button';

import { useHistory } from 'react-router-dom';
import { ReactComponent as IconKtp } from 'assets/images/ic-ktp.svg';
import { ReactComponent as IconPhoto } from 'assets/images/cam-with-border.svg';
import { ReactComponent as IconCheck } from 'assets/images/icon-check-border.svg';
import { ReactComponent as IconArrowRight } from 'assets/images/ic-arrow-right.svg';
import './photo-verification-button.scss';

const PhotoVerificationButton = props => {
  const history = useHistory();
  const {
    tag: Tag,
    text,
    isCompleted,
    requesting,
    className,
    description,
    type,
    linkRedirect,
    ...attributes
  } = props;

  const renderIcon = () =>
    type === 'ktp' ? <IconKtp width="60" /> : <IconPhoto width="60" />;

  const renderIconCompleted = () =>
    isCompleted ? <IconCheck width="60" /> : renderIcon();

  const classes = classNames(
    `PannelPhoto`,
    isCompleted ? 'Color-secondary' : 'Color-primary',
    isCompleted ? 'PannelPhoto--green' : 'PannelPhoto--blue',
    className,
  );

  const redirectUrl = () =>
    !isCompleted && history.push(!isCompleted && linkRedirect);

  return (
    <div className={classes} {...attributes} onClick={() => redirectUrl()}>
      <div className="Padding-xsmall">
        <div className="Grids AlignItems-center">
          <div className="Grid Grid-2">{renderIconCompleted()}</div>
          <div className="Grid Grid-8">
            <div className="FontWeight-bold">{text}</div>
            <p className="Margin-0 Typography-caption12 Color-dark">
              {isCompleted ? 'Berhasil disimpan! ' : description}
            </p>
          </div>
          <div className="Grid Grid-2">
            {!isCompleted && (
              <IconArrowRight width="24" className="Color-primary" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

PhotoVerificationButton.defaultProps = {
  tag: Button,
};

export default PhotoVerificationButton;
