import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useApiInvoker } from '@tunaiku.npm/react-library/dist/cjs/modules/api-invoker';
import { SnackbarContext as Snackbar } from 'modules/components/snackbar';
import useStorageManager from 'modules/services/storage-manager.hook';

import { SENYUMKU_BALANCE_ENDPOINT } from '../constants/senyumku-balance.const';
import { LOCAL_STORAGE } from 'modules/constants/local-storage.const';
import { ROUTES } from 'modules/constants/routes.const';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';

const useSenyumkuBalance = () => {
  const history = useHistory();
  const { apiInvoker } = useApiInvoker({});
  const { openSnackbar } = useContext(Snackbar);
  const [senyumkuBalance, setSenyumkuBalance] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const { getEncryptedDataFromLocalStorage } = useStorageManager();

  useEffect(() => {
    fetchSenyumkuBalance();
  }, []);

  const getCustomerId = () => {
    const customerLocalStorage = getCustomerDataFromLocalStorage();
    return customerLocalStorage.customer_id;
  };

  const getCustomerDataFromLocalStorage = () =>
    getEncryptedDataFromLocalStorage(LOCAL_STORAGE.customer);

  const fetchSenyumkuBalance = () => {
    if (!getCustomerDataFromLocalStorage())
      return history.push(ROUTES.dashboard);

    if (getCustomerId()) {
      setSpinner(true);
      apiInvoker
        .get(SENYUMKU_BALANCE_ENDPOINT)
        .then(handleGetSenyumkuBalanceSuccess)
        .catch(handleSenyumkuBalanceFailed);
    }
  };

  const handleGetSenyumkuBalanceSuccess = res => {
    const { Data: data } = res.data;
    setSpinner(false);
    setSenyumkuBalance(data);
  };

  const handleSenyumkuBalanceFailed = () =>
    openSnackbar({ value: VALIDATION_MESSAGES.GLOBAL_ERROR_HANDLE });

  return {
    senyumkuBalance,
    spinner,
  };
};

export default useSenyumkuBalance;
