import {
  MIN_SAVING,
  MAX_SAVING,
  SPECIALLY_ALLOWED_TOP_UP_NOMINAL,
  ENV_DEVELOPMENT,
} from 'modules/constants/product.const';
import VALIDATION_MESSAGES from 'assets/json/validation-messages.json';
import env from 'modules/env/env';

export const nominalValidators = (value = '') => {
  const isDevelopment = env === ENV_DEVELOPMENT;

  if (isDevelopment && Number(value) === SPECIALLY_ALLOWED_TOP_UP_NOMINAL) {
    return '';
  }

  if (Number(value) < MIN_SAVING) {
    return VALIDATION_MESSAGES.FIELD_CALCULATION_MIN_SAVING;
  } else if (Number(value) > MAX_SAVING) {
    return VALIDATION_MESSAGES.FIELD_CALCULATION_MAX_SAVING;
  }
};
