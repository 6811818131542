import React from 'react';
import classNames from 'classnames';

const defaultProps = {
  tag: 'input',
};

const Input = props => {
  /* prettier-ignore */
  const { 
    tag: Tag,
    className,
    children,
    isFieldError,
    ...attributes
  } = props;

  const cssClasses = classNames(
    `Input`,
    {
      [`Input--danger`]: !!isFieldError,
    },
    className,
  );

  return <Tag {...attributes} className={cssClasses} />;
};

Input.defaultProps = defaultProps;

export default Input;
