import React, { memo, useEffect, useState } from 'react';

import { connect } from 'formik';
import { useOrderedNodes } from 'react-register-nodes';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';

const defaultProps = {
  tag: 'form',
};

const Form = memo(props => {
  const [checkForError, setCheckForError] = useState(false);

  /* prettier-ignore */
  const { 
    tag: Tag,
    children, 
    onSubmit,
    className, 
    formik,
    ...attributes
  } = props;

  const { errors } = formik;
  const nodes = useOrderedNodes();

  useEffect(() => {
    const errKeys = Object.keys(formik.errors);
    const errNodes = nodes.filter(node => {
      const inputName = node
        .querySelector('input, select, textarea')
        .getAttribute('name');

      return errKeys.includes(inputName);
    });

    // scroll to the first error field.
    if (checkForError && errNodes.length > 0) {
      smoothScrollIntoView(errNodes[0], {
        scrollMode: 'if-needed',
        block: 'center',
        inline: 'start',
      }).then(() => {
        errNodes[0].querySelector('input, select, textarea').focus();
        setCheckForError(false);
      });
    }
  }, [checkForError, nodes, errors]);

  return (
    <Tag
      {...attributes}
      onSubmit={e => {
        e.preventDefault();
        setCheckForError(true);
        formik.handleSubmit(e);

        if (onSubmit) {
          onSubmit(formik.values);
        }
      }}
      className={className}>
      {children}
    </Tag>
  );
});

Form.defaultProps = defaultProps;

export default connect(Form);
