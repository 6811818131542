import React from 'react';
import classNames from 'classnames';

import { Button } from 'modules/components/button';
import { Separator } from 'modules/components/separator';
import ResultCalculation from './modules/components/result-calculation';
import NominalCalculation from './modules/components/nominal-calculation';
import PeriodInterestCalculation from './modules/components/period-interest-calculation';
import useCalculation from 'pages/modules/components/calculation/modules/services/calculation.hook';

import { INPUT_THOUSAND_SEPARATOR_REGEX } from 'modules/constants/regex.const';
import SimulationValidation from 'assets/images/simulation-validation-1b.png';

const Calculation = props => {
  const { content, theme, idAction } = props;

  const {
    state,
    handleChange,
    depositNominal,
    handleSubmit,
    isDisableButton,
    handleNominalDeposit,
    handleToTopUpReminder,
    periodInterestList,
    handleSelectedPeriod,
    isNominalAboveOneBillion,
    isInsideTopUp,
  } = useCalculation(props);

  const nominalAttributes = {
    id: 'TxtAmountDeposit',
    name: 'value',
    type: 'tel',
    autoComplete: 'off',
    value: handleNominalDeposit(),
    onChange: handleChange,
  };

  const cssClasses = classNames('Padding Margin-0', {
    'BgColor-white': !isInsideTopUp,
  });

  const nominalCalculation = depositNominal.replace(
    INPUT_THOUSAND_SEPARATOR_REGEX,
    '.',
  );

  const dataResultCalculation = {
    nominalCalculation,
    isInsideTopUp,
  };

  return (
    <div className="Calculation">
      <div className="Flex-column FlexGrow-1 bg-white">
        <div className="Card-body Padding">
          {isNominalAboveOneBillion && (
            <img
              src={SimulationValidation}
              width="100%"
              className="MarginBottom"
              alt="simulation-validation-one-billion"
            />
          )}

          <NominalCalculation
            nominal={content.nominal}
            errorMessage={state.errors}
            nominalAttributes={nominalAttributes}
          />

          <Separator />

          <PeriodInterestCalculation
            activeButton={state.activeButton}
            periodInterestList={periodInterestList}
            handleSelectedPeriod={handleSelectedPeriod}
            isNominalAboveOneBillion={isNominalAboveOneBillion}
          />

          <ResultCalculation {...dataResultCalculation} />
        </div>
      </div>

      {!isInsideTopUp && <Separator className="Margin-0" />}

      <div className={cssClasses}>
        <Button
          theme={theme}
          className="Padding-small"
          fullWidth
          onClick={handleSubmit}
          disabled={isDisableButton}
          id={idAction}>
          {content.button}
        </Button>
        {isInsideTopUp && (
          <Button
            id="LinkToTopUpReminder"
            theme="primary"
            className="MarginTop-small"
            onClick={handleToTopUpReminder}
            isText
            fullWidth>
            Ingatkan saya untuk Transfer
          </Button>
        )}
      </div>
    </div>
  );
};

export default Calculation;
