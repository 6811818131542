import React from 'react';

import useTimePicker from './modules/services/time-picker.hook';

import {
  REMINDER_CONSTANTS,
  IMAGE_TIME_LOGO,
  INPUT_HOUR,
  INPUT_MINUTE,
} from 'pages/dashboard/top-up/top-up-reminder/modules/constant/top-up-reminder.const';
import timelogo from 'assets/images/bx-time-five.svg';
import './time-picker.scss';

const TimePicker = props => {
  const {
    hourValue,
    minuteValue,
    handleHourChange,
    handleMinuteChange,
  } = useTimePicker(props);

  return (
    <div className="TimePicker MarginTop MarginBottom">
      <img
        src={timelogo}
        id={IMAGE_TIME_LOGO}
        className="TimePicker-logo"
        alt="time icon"
      />
      <input
        id={INPUT_HOUR}
        type="number"
        min="0"
        inputmode="numeric"
        pattern="[0-9]*"
        autoComplete="off"
        className="TimePicker-input"
        value={hourValue}
        onChange={handleHourChange}
      />
      <div className="TimeSeparator">{REMINDER_CONSTANTS.separator}</div>
      <input
        id={INPUT_MINUTE}
        type="number"
        min="0"
        inputmode="numeric"
        pattern="[0-9]*"
        autoComplete="off"
        className="TimePicker-input"
        value={minuteValue}
        onChange={handleMinuteChange}
      />
      <p className="Margin-0">{REMINDER_CONSTANTS.timezone}</p>
    </div>
  );
};

export default TimePicker;
