export const ACCOUNT_STEPS = [
  { name: 'Buat akun' },
  { name: 'Lengkapi data' },
  { name: 'Verifikasi' },
  { name: 'Buat Deposito' },
];

export const DASHBOARD_ACCOUNT_ENDPOINT = '/dashboard/growth';

export const ACCOUNT_NUMBER_CLIPBOARD_MESSAGE = 'No. Rekening berhasil disalin';
