import React from 'react';
import classNames from 'classnames';
import './radio.scss';

const Radio = props => {
  // prettier-ignore
  const {
		tag: Tag,
    innerTag: InnerTag,
		className,
    children,
    label,
		...attributes
	} = props;

  const classes = classNames(`Radio`, className);

  return (
    <Tag className={classes}>
      <InnerTag id={attributes.id} {...attributes} />
      <label htmlFor={attributes.id}>{label}</label>
    </Tag>
  );
};

Radio.defaultProps = {
  tag: 'div',
  innerTag: 'input',
  type: 'radio',
};

export default Radio;
