import React from 'react';
import axios from 'axios';
import LandingOriginal from './modules/components/landing-original';
import usePageData from 'pages/modules/services/page-data.hook';
import { API } from 'modules/env';
import 'pages/landing/landing.scss';

const { CMS_URL } = API;

const initialPageData = {
  faqList: [],
};

const getPageData = async () => {
  const response = await axios.get(`${CMS_URL}?landing=deposito-faq`);
  const { 'deposito-faq': depositoFaq } = response.data;

  return {
    faqList: depositoFaq,
  };
};

const Landing = props => {
  const { pageData } = usePageData(props, { initialPageData, getPageData });

  return <LandingOriginal faqList={pageData.faqList} />;
};

Landing.initialPageData = initialPageData;
Landing.getPageData = getPageData;

export default Landing;
