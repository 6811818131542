import { useReducer, useEffect } from 'react';
import usePeriodList from 'pages/modules/services/period-list.hook';
import { basicReducer } from 'modules/utilities/state.util';

import {
  LIST_COMPARISON,
  START_INDICATOR,
  END_INDICATOR,
  STEP_INDICATOR,
} from '../constants/product-comparison.const';

const useProductComparison = () => {
  const { minInterestRate, maxInterestRate } = usePeriodList();

  const defaultValues = {
    indicator: START_INDICATOR,
    productComparisonList: LIST_COMPARISON,
  };
  const [state, setState] = useReducer(basicReducer, defaultValues);
  const { indicator, productComparisonList } = state;

  useEffect(() => {
    setInterestRateFromAPI();
  }, [minInterestRate, maxInterestRate]);

  const setNextIndicator = () => {
    if (indicator < END_INDICATOR) {
      return setState({ indicator: indicator + STEP_INDICATOR });
    }
    return setState({ indicator: START_INDICATOR });
  };

  const setPrevIndicator = () => {
    if (indicator !== START_INDICATOR) {
      return setState({ indicator: indicator - STEP_INDICATOR });
    }
    return setState({ indicator: END_INDICATOR });
  };

  const getDataComparison = () =>
    productComparisonList.find(({ id }) => id === indicator);

  const setInterestRateFromAPI = () => {
    setState({
      productComparisonList: [
        {
          ...productComparisonList[0],
          data: [
            `${minInterestRate} - ${maxInterestRate}%`,
            ...productComparisonList[0].data.slice(
              1,
              productComparisonList[0].data.length,
            ),
          ],
        },
        ...productComparisonList.slice(1, productComparisonList.length),
      ],
    });
  };

  return {
    getDataComparison,
    setNextIndicator,
    setPrevIndicator,
  };
};

export default useProductComparison;
